import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AuditService } from '../AuditService/audit.service';
import { AuthService } from '../core/auth.service';
import { UserService } from '../core/user.service';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';
import { FirebaseUserModel } from '../core/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {

  user: FirebaseUserModel = new FirebaseUserModel();
  ispasswordexpired: boolean;
  fireuser: any;
  resetFlag: boolean = true;
  uid: string;
  fire_token: string;
  token: string;
  setSIDURL_testapp: string;
  setSIDURL_myqone: string;
  setSIDURL_qinsights: string;
  setSIDURL_qadmin: string;
  setSIDURL_qonductor: string;
  setSIDURL_obuzz: string;
  setSIDURL_qcollege: string;
  userDetails: any;
  userInfo: any;
  navigate: boolean;
  useSetCookie: boolean;
  returnUrl: string;
  username : string;
  
  constructor(public authService: AuthService,
    private router: Router,
    private location: Location,
    public userService: UserService,
    private cookieService: CookieService,
    private auditService: AuditService,

    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,) { }

   
  validateDuoUser(resData : any , uid : any){
    this.userService.setSessionStorage("username", resData.username);

    this.auditService.getname(resData.firstname + " " + resData.lastname);
    this.auditService.getid(resData.userid);
    this.auditService.callApI("User Login Successful",  resData.token);
    this.userService.setSessionStorage('userid', resData.userid);
    this.userService.setSessionStorage("fullname", resData.firstname + " " + resData.lastname);
    this.token = resData.token;
    this.ispasswordexpired = resData.ispasswordexpired;
    this.uid = uid;
    //  console.log("ispassword", this.ispasswordexpired);
    let isFirst: boolean;
    isFirst = false;

    if (resData) {
      if (resData === undefined) {
        isFirst = true;
      }
      else {
        if (resData.isfirstlogin || resData.isfirstlogin === "" || resData.isfirstlogin === null) {
          isFirst = true;
        }
      }
    }
    else {
      isFirst = true;
    }
    if (isFirst) {
      this.navigate = false;
      this.router.navigate(["/profileupdate", resData.userid, resData.token], { queryParamsHandling: 'preserve' });
    }
    else
      if (this.ispasswordexpired && this.userService.getSessionStorage('loginAd') != 'adUser') {
        //  console.log(this.ispasswordexpired)  /// do change  here to show reset password screen(set flag from api)
        //  this.navigate = false;
        this.router.navigate(["/resetpassword", resData.userid, resData.token], { queryParamsHandling: 'preserve' });
        //  this.logout(); // back to login
      }

      else {
        this.userService.removeSessionStorage('loginAd');
        if(resData.mfaenabled == true) {
          const encodedUrl = this.userService.getLocalStorage('continuePath')
          this.returnUrl = decodeURIComponent(encodedUrl);
        }else{
          this.returnUrl = this.route.snapshot.queryParams['continue'];
        }
        
        if (this.returnUrl) {
          //console.log("Back url : " + this.returnUrl);
        }
        else {
          this.returnUrl = environment.myqone_url + "/puthash/landing";  //environment.obuzz_url;
          //console.log("No back url : " + this.returnUrl);
        }

        //if (this.returnUrl.includes(environment.qadmin_url) || this.returnUrl.includes(environment.qpathways_url) || this.returnUrl.includes(environment.qpathways_admin_url) || this.returnUrl.includes(environment.myqone_url + "/puthash") || this.returnUrl.includes(environment.qcollege_url) || this.returnUrl.includes(environment.qinsights_url) || this.returnUrl.includes(environment.obuzz_url) || this.returnUrl.includes(environment.qonductor_url)) {
        if (this.returnUrl.includes("puthash") || (this.returnUrl.includes(environment.qwebemr_url) && environment.qwebemr_url !== "")) {
          this.useSetCookie = false;
        }
        else {
          this.useSetCookie = true;
          if (this.returnUrl.includes(environment.qbots_url)) {
            this.setSIDURL_testapp = this.returnUrl + "/setSID/" + this.uid + "/" + this.token;
          }
          else {
            this.setSIDURL_testapp = this.returnUrl + "/#/setSID/" + this.uid + "/" + this.token;
          }
        }
        // this.setSIDURL_testapp = "https://microservices.qpathways.com/ssotest/#/setSID/" + this.uid + "/" + this.token;
        // this.setSIDURL_myqone = environment.myqone_url + "/#/setSID/" + this.uid + "/" + this.token;
        // this.setSIDURL_qinsights = environment.qinsights_url + "/#/setSID/" + this.uid + "/" + this.token;
        // this.setSIDURL_qadmin = environment.qadmin_url + "/#/setSID/" + this.uid + "/" + this.token;
        // this.setSIDURL_qonductor = environment.qonductor_url + "/#/setSID/" + this.uid + "/" + this.token;
        // this.setSIDURL_obuzz = environment.obuzz_url + "/#/setSID/" + this.uid + "/" + this.token;
        // this.setSIDURL_qcollege = environment.qcollege_url + "/#/setSID/" + this.uid + "/" + this.token;
        //console.log("Fire Response: " +  JSON.stringify(res));

        //console.log(this.setSIDURL_testapp);

        if (environment.currentEnvironment === "Local") {
          this.userService.setCookies('UID', this.uid, 30, undefined, undefined, false, "Lax");
          this.userService.setCookies('AID', this.token, 30, undefined, undefined, false, "Lax");
        }
        else {
          this.userService.setCookies('UID', this.uid, 30, undefined, undefined, true, "None");
          this.userService.setCookies('AID', this.token, 30, undefined, undefined, true, "None");
        }
        this.redirectBack(this.uid);
      }
  }
  redirectBack(uid : any) {
    if (this.useSetCookie === false) {
      this.returnUrl = this.returnUrl.replace("puthash", "#")
      window.location.replace(this.returnUrl + "/" + uid + "/" + this.token);
    }
    else {
      setTimeout(() => {
        // this.router.navigate(['login']);
        window.location.replace(this.returnUrl);
      }, 6000);
      // window.location.assign("http://dev.myqone.com");
    }
  }

  logout() {
    this.authService.doLogout().then(
      res => {
        this.cookieService.delete('UID');
        this.cookieService.delete('AID');
        this.location.back();
      },
      error => {
        console.log("Logout error", error);
      }
    );
  }
}
