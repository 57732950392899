import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment"
import { Observable } from 'rxjs';
import { PatientUserModel } from 'src/app/Entities/PatientUserModel';
import { TaskModel } from 'src/app/Entities/TaskModel';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  update(data: any, token: any) {
    var strServiceURL = environment.service_url + "users/update";
    return this.http.post<any>(strServiceURL, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  getUserType(token: any): Observable<any> {
    var strServiceURL = environment.service_url + "usertype/getAll";
    return this.http.get<any>(strServiceURL, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  emailhandler(actioncode: any): Observable<any> {
    var para = "{\"oobCode\":\"" + actioncode + "\"}";
    return this.http.post<any>("https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=" + environment.firebase.apiKey, para);
  }

  getAdSignin(data: any): Observable<any> {
    var strServiceURL = environment.service_url + "cicp/adsignin";
    return this.http.post<any>(strServiceURL, data);
  }
  resetPassword(data: any, token: any): Observable<any> {
    var strServiceURL = environment.service_url + "users/changepassword";
    return this.http.post<any>(strServiceURL, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }
  resetpasswordbyemail(data: any, token: any): Observable<any> {
    var strServiceURL = environment.service_url + "users/resetpasswordbyemail";
    return this.http.post<any>(strServiceURL, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }
  saveLocalUser(data: any, token: any): Observable<any> {
    var strServiceURL = environment.service_url + "users/save";
    return this.http.post<any>(strServiceURL, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }


  chkbynpi(npi ,token: any): Observable<any> {
    return this.http.get<any>(environment.host + "practitionerbynpi/" + npi,{ headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  markEmailVerified(CICPID: any): Observable<any> {
    var strServiceURL = environment.service_url + "users/setEmailVerified/" + CICPID;
    //console.log(strServiceURL);
    return this.http.get<any>(strServiceURL);
  }
  // user
  UserFound(mb): Observable<any> {
    return this.http.get<any>(environment.service_url + 'users/userexistbymobilenumber/' + mb);
  }
  isUserExists(email): Observable<any> {
    return this.http.get<any>(environment.service_url + "users/userexists?username="+email);
  }
  //service call to save patient user
  savePatientUser(obj: PatientUserModel, token: string) {
    return this.http.post<any>(`${environment.service_url}users/saveUser_Reg`, obj, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  // service call to save assessment task for user in qpathways
  saveTask(obj: TaskModel, token: string) {
    return this.http.post<any>(environment.host + "task/save_new", obj, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }
  getProviderByNPI(obj) {
    if (obj.firstname == null) obj.firstname = "";
    if (obj.lastname == null) obj.lastname = "";
    if (obj.npi == null) obj.npi = "";
    if (obj.postalCode == null) obj.postalCode = "";
    if (obj.city == null) obj.city = "";
    if (obj.state == null) obj.state = "";
    if (obj.taxonomy == null) obj.taxonomy = "";
    return this.http.get<any>(environment.auditUrl + "directoryservice//npiregistry/getAllProviders?firstname=" + obj.firstname + "&lastname=" + obj.lastname + "&npi=" + obj.npi + "&city=" + obj.city + "&state=" + obj.state + "&postalCode=" + obj.postalCode + "&taxonomy=" + obj.taxonomy + "&pagenumber=0&size=20&sortby=providerid");
  }
}
