import { NgModule } from "@angular/core";
import {
  MatInputModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatTabsModule,
  MatToolbarModule,
  MatBadgeModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatGridListModule,

  MatExpansionModule,
  MatListModule,
  MatRippleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
  MatSlideToggleModule,
  MatSelectModule
} from "@angular/material";

const MaterialComponents = [
  MatInputModule,
  MatButtonModule,

  MatSelectModule,
  MatIconModule,
  MatFormFieldModule,
  MatCardModule,
  MatCheckboxModule,
  MatTabsModule,
  MatToolbarModule,
  MatBadgeModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatGridListModule,
  MatExpansionModule,
  MatListModule,
  MatSlideToggleModule,
  MatRippleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatSelectModule
];

@NgModule({
  imports: [MaterialComponents],
  exports: [MaterialComponents]
})
export class MaterialModule { }
