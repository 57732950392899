import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserService } from "../core/user.service";
import { log } from "console";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router
  ) {
    // console.log("auth guard init -----------");

    //   this.userService.getBackArrowDisableOfBrowser();
  }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      // console.log("State url 1 -------- >  ", state?.url);
      // if(state?.url.includes('/login')){
        // console.log("return to login 1");
      //   this.userService.getBackArrowDisableOfBrowser();
      // }

    return new Promise((resolve, reject) => {
      let returnUrl = next.queryParams['continue'];
      this.userService.getCurrentUser().then(
        user => {
          this.router.navigate(["/user"], { queryParams: { continue: returnUrl } });
          return resolve(false);
        },
        err => {
          // console.log("State url 2 ----- >  ", state?.url);
          // if(state?.url.includes('/login')){
          //   // console.log("return to login 2");
          //   this.userService.getBackArrowDisableOfBrowser();
          // }
          return resolve(true);
        }
      );
    });
  }
}
