import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ForgotpasswordComponent } from "./forgotpassword/forgotpassword.component";
import { UserComponent } from "./user/user.component";
import { AuthGuard } from "./core/auth.guard";
import { UserResolver } from "./user/user.resolver";
import { ProfileupdateComponent } from "./profileupdate/profileupdate.component";
import { LogoutComponent } from "./logout/logout.component";
import { EmailverificationComponent } from './emailverification/emailverification.component';

import { ResetpasswordComponent } from "./resetpassword/resetpassword.component";
import { ActivedirectoryComponent } from "./activedirectory/activedirectory.component";
import { PhoneloginComponent } from './phonelogin/phonelogin.component';
import { DuoNotificationComponent } from "./duo-notification/duo-notification.component";

export const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent, canActivate: [AuthGuard] },
  // { path: "register", component: RegisterComponent, canActivate: [AuthGuard] },
  { path: "resetpassword/:userid/:token", component: ResetpasswordComponent },
  {
    path: "forgotpassword",
    component: ForgotpasswordComponent,
    canActivate: [AuthGuard]
  },
  { path: "user", component: UserComponent, resolve: { data: UserResolver } },
  { path: "profileupdate/:userid/:token", component: ProfileupdateComponent },
  { path: "logout", component: LogoutComponent },
  // { path: "phonelogin", component: PhoneloginComponent, canActivate: [AuthGuard] },
  { path: "emailhandler", component: EmailverificationComponent },
  { 
    path: "duocallback/:state/:code",
    component: DuoNotificationComponent 
  },
  // { path: "activedirectory", component: ActivedirectoryComponent }
];
//
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }