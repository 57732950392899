export class user {
    "userid": string;
    "firstname": string;
    "lastname": string;
    "mobilenumber": string;
    "birthdate": string;
    "gender": string;
    "nusertypeid": string;
    "snpi": string;
    "email": string;
    "newpassword": string;
}