<div class="row">
  <div class="col ml-4">
    <span>Hang on a moment while we sign you out...</span>
  </div>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_myqone | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_qinsights | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_qadmin | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_qonductor | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_obuzz | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_qcollege | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_qbots | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_banq | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_qpathways_admin | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_qpathways | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_webemr | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_patEng | safe" style="display: none"></iframe>
</div>
<div class="row">
  <iframe [src]="this.setSIDURL_bcbsm | safe" style="display: none"></iframe>
</div>