import { Injectable } from "@angular/core";
// import 'rxjs/add/operator/toPromise';
import { Observable, Subject } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import  firebase from "firebase/app";
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthService {
  isInvalidLogin = new Subject();
  constructor(public afAuth: AngularFireAuth, private userService: UserService, private router: Router) { }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithRedirect(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  doMicrosoftLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.OAuthProvider('microsoft.com');
      this.afAuth.signInWithRedirect(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {

          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");

      //this.afAuth.auth.signInWithRedirect
      //signInWithPopup

      this.afAuth.signInWithRedirect(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
  // do changes her add resetpassword api call.................
  sendPasswordResetEmail(value) {
    return new Promise<void>((resolve, reject) => {
      var auth = firebase.auth();
      var emailAddress = value.email;
      // console.log("auth user in sendpasswordreset", auth);

      auth.sendPasswordResetEmail(emailAddress).then(function () {
        resolve();
      }
      ).catch(function (error) {
        reject(error);
      }
      );
    });
  }

  doRegister(value, queryParam?: string) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password) //save to firebase
        .then(
          res => {
            // save user Locally
            var user = firebase.auth().currentUser;
            var username = user.email;
            this.userService.sendVerificationEmail(username, queryParam).subscribe(res1 => {  // java service..
              //    console.log("varification", res1.status);
              if (res1.status == "Email was sent successfully") {
                //    console.log(res1.status);
                firebase.auth().signOut();
                resolve(res);
              } else {
                // console.log(res1.status);
              }
            })

          },
          err => reject(err)
        );
    });
  }

  doLogin(value) {
    //console.log("In Do Login...")
    this.isInvalidLogin.next(false);
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
          .then(
          res => {
            //firebase.auth().signInWithCustomToken
            var user = firebase.auth().currentUser;
            firebase.auth().currentUser.getIdToken(true)
            .then((token) => {
              this.userService.sTokenValue = token
            });
            if (user.emailVerified) {
              //console.log("EMail Verified")
              resolve(res);

            }
            else {
              firebase.auth().signOut();
              //console.log("EMail Not Verified")
              this.isInvalidLogin.next(true);
            }
          },
          err => reject(err)
        );
    });
  }


  doAdLogin(value) {
    console.log("In Do Login...")
    this.isInvalidLogin.next(false);
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithCustomToken(value)
        .then(
          res => {
            var user = firebase.auth().currentUser;
            resolve(res);
            // if (user.emailVerified) {
            //   console.log("EMail Verified")
            //   resolve(res);
            // }
            // else {
            //   firebase.auth().signOut();
            //   console.log("EMail Not Verified")
            //   this.isInvalidLogin.next(true);
            // }

          },
          err => reject(err)
        );
    });
  }

  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.signOut();
        resolve();
      } else {
        reject();
      }
    });
  }
}