import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../core/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from '../core/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { promise } from 'protractor';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { AuditService } from '../AuditService/audit.service';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase";
import { WindowService } from '../shared/window.service';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();



@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  windowRef: any;
  hide = true;
  loginForm: FormGroup;
  public errorMessage: string = "";
  color: string;
  public viewtype = "email";
  public view;
  ismbORemail;
  public bgTrans: boolean = false;
  returnUrl: string;
  emailverified: boolean;
  public username: string = "";
  public cookieEnabled: boolean;
  token: string;
  LoginUser: string;
  tost: boolean = true;
  successMessage: string = "";
  successMessage1: string = "";
  isValid_mb; isMB_Email;
  errMessage: string = "";
  resetFlag = true;
  verificationCode: string; user: any;

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private auditService: AuditService,
    private afAuth: AngularFireAuth,
    private win: WindowService


  ) {
    this.createForm();
    this.returnUrl = this.route.snapshot.queryParams['continue']
    if (this.returnUrl) {
      //console.log("Login back url : " + this.returnUrl);
    }
    else {
      //console.log("No login back url : " + this.returnUrl);
    }
    // let va = sessionStorage.getItem("resetFlag"); console.log(va); Trigger Build


  }

  ngOnInit() {
    // console.log("Login init -------- ");
    // this.userService.getBackArrowDisableOfBrowser();
    window.history.replaceState(null, null, null)
    // window.addEventListener("pageshow", function (event) {
    //   if (event.persisted) {
    //     console.log("pageshow");
    //     window.location.reload();
    //   }
    // });

    this.LoginUser = this.userService.getLocalStorage("adLogin");
    //console.log("loginUser", this.LoginUser);
    if (this.LoginUser === "adlogin") {
      this.router.navigate(["/activedirectory"], { queryParamsHandling: 'preserve' }); // if ADlogin user navigate to AD
    }

    this.authService.isInvalidLogin.subscribe((res: boolean) => {
      this.emailverified = res;
    }, err => {
      // sessionStorage.setItem("loginerr", "loginerr");
      //  this.auditService.callApI();
      // console.log("error in login", err);
    });
    // if (sessionStorage.getItem('resetFlag') == 'true') {
    //   sessionStorage.setItem('resetFlag', 'false');
    //   console.log("construct");
    //   this.successMessage1 = "Your password has been changed.Login with your new password.";

    // }
  }

  loginusertype(user) {
    // console.log("type",user);
    this.userService.setLocalStorage("adLogin", "adlogin");
  }
  createForm() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required]
    });
  }

  tryFacebookLogin() {
    this.authService.doFacebookLogin().then(res => {
      this.router.navigate(["/user"], { queryParamsHandling: 'preserve' });
    });
  }

  tryMicrosoftLogin() {
    //console.log("Microsoft Login");
    var originalCatch = Promise.prototype.catch;
    // let e1: string = "";
    let p1 = this.authService.doMicrosoftLogin().then(res => {
      // console.log(res);
      this.router.navigate(["/user"], { queryParamsHandling: 'preserve' });
    });
    let p2 = p1.catch(function (error) {   // catch error from promise
      //console.log("error", error.code);
      // e1 = error.code;
      return error;
    });
    // console.log(p2);
    p2.then(res => {
      if (res.code === "auth/account-exists-with-different-credential") {
        this.errorMessage = "This login is already in use with a different provider. Use a different login.";
      }
      else if (res.code === "auth/popup-closed-by-user") {
        // console.log(res.code);
      }
      else if (res.code === "auth/network-request-failed") {
        //  console.log(res.code);

      }
    })
  }


  tryTwitterLogin() {
    this.authService.doTwitterLogin().then(res => {
      this.router.navigate(["/user"], { queryParamsHandling: 'preserve' });
    });
  }

  tryGoogleLogin() {
    this.authService.doGoogleLogin().then(res => {
      this.router.navigate(["/user"], { queryParamsHandling: 'preserve' });
    });
  }

  userData: any;

  tryLogin(value) {
    //  console.log("value in do login", value);
    this.errorMessage = "";
    //console.log("Try Login...")
    this.authService.doLogin(value).then(
      res => {
        //  console.log("Got Do Login", res);
        this.userService.getCurrentUser().then((resp) => {
          //  console.log("In do login respons);
          this.router.navigate(["/user"], { queryParamsHandling: 'preserve' });
        }
        )
      },
      err => {

        // console.log(" Try Login -------- ");
        // this.userService.getBackArrowDisableOfBrowser();
        //console.log("Error Code: " + err.code);
        //console.log("Error Message: " + err.message);

        if (err.code == "auth/too-many-requests") {
          this.errorMessage = "Too many unsuccessful login attempts. For security reasons your account is locked temporarily. Please try again later.";
        }else {
          // if (err.message === "The email address is badly formatted.")
          //   this.errorMessage = "";
          this.errorMessage = "Invalid username or password"
          // err.message;

          // console.log(this.errorMessage);
        }
      }
    );

  }
  set() {
    this.username = this.loginForm.value.email;
    this.errorMessage = "";
    this.setviewtype("pass")
    // firebase.auth().fetchSignInMethodsForEmail(this.loginForm.value.email).then((methods) => {
    //   //  console.log("methods ", methods.length)
    //   if (methods.length == 0) {
    //     this.errorMessage = "Email not Found.";
    //     this.viewtype = "email";
    //   } else {
    //     this.setviewtype("pass");
    //   }
    // }, err => {
    //   this.viewtype = "email";
    //   this.errorMessage = err.message;
    //   // console.log(err.message);
    // });
  }
  sendLoginCode() {
    this.errorMessage = "";
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
      }

    }
    )
    this.windowRef.recaptchaVerifier.render();
    const appVerifier = this.windowRef.recaptchaVerifier;
    this.username = this.loginForm.value.email;
    firebase.auth().signInWithPhoneNumber(this.username, appVerifier)
      .then(result => {
        this.windowRef.confirmationResult = result; this.setviewtype("pass");
      })
      .catch(error => {
        // console.log(error);
        this.errorMessage = "The mobile number is badly formatted ";
        this.viewtype = "email";
      });

  }
  verifyLoginCode() {

    this.verificationCode = this.loginForm.value.password;

    this.userinfo().then(res => {
      this.userService.getCurrentUser().then((resp) => {
        //console.log("In do login respons", resp);
        this.router.navigate(["/user"], { queryParamsHandling: 'preserve' });
      }
      )
    })

  }
  userinfo() {
    return new Promise<any>((resolve, reject) => {
      this.windowRef.confirmationResult
        .confirm(this.verificationCode)
        .then(result => {
          this.user = result.user;
          // console.log("user", this.user);
          resolve(this.user)
          // this.token = this.user.ra;
        })
        .catch(error => console.log(error, "Incorrect code entered?"));
    });
  }
  setviewtype(type) {
    this.errorMessage = "";
    this.username = this.loginForm.value.email;
    this.viewtype = type;
    this.cookieEnabled = navigator.cookieEnabled;
    //console.log("cookie", this.cookieEnabled);
    if (!this.cookieEnabled) {
      this.viewtype = "cookie";
      this.cookieEnabled = true;
    }
    this.bgTrans = true;
  }

  setview(type) {
    this.viewtype = type;
  }
  pagelink() {
    let username = this.loginForm.get("email").value;
    //  console.log(this.loginForm.get("email").value);
    this.userService.sendVerificationEmail(username).subscribe(res => {
      //  console.log(res);
      if (res.status == "Email was sent successfully") {
        this.successMessage = "Email verification link has been sent to your mail account. Verify your account to continue.";
        this.tost = false;
        //  console.log(this.tost);
      }
    }, err => {
      this.errMessage = "Unable to send Email..."

    }
    )
  }

  validateNumber(value) {
    var regEx = /^(?!0+$)(?:\(?\+\d{1,2}\)?[- ]?|0)?\d{10,12}$/;
    var reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
    var mb = regEx.test(value);
    var email = reg.test(value)
    if (!mb && !email) {
      this.isMB_Email = "";
    }
    else if (mb) {
      this.isMB_Email = "mobile";
      try {
        this.isValid_mb = phoneUtil.isValidNumber(phoneUtil.parse(value));
        if (this.isValid_mb)
          this.isMB_Email = "mobile";

      } catch (e) {
        console.log("errpo", e)
        this.isValid_mb = false;
        this.errorMessage = "The mobile number is badly formatted ";
      }
    }
    else if (email) {
      this.isMB_Email = "email";
      this.set();
    }

  }
  userInput() {
    this.validateNumber(this.loginForm.get("email").value);
    // console.log(this.isValid_mb, this.isMB_Email);
    if (this.isMB_Email == "") {
      this.errorMessage = "The email/mobile is badly formatted."
      this.viewtype = "email";
    }
    if (this.isMB_Email === "mobile") {
      if (this.isValid_mb === false) {
        this.errorMessage = "The mobile number is badly formatted ";
        this.viewtype = "email";
      }
      else if (this.isValid_mb === true) {
        this.sendLoginCode();
        //this.setviewtype("pass");

      }
    }


  }
  TryLogin(value) {
    if (this.isMB_Email === "email")
      this.tryLogin(value);
    else if (this.isMB_Email == "mobile")
      this.verifyLoginCode();

  }

}