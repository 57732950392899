

export class auditcls {
  "application": string;
  "auditlogcode": string;
  "city": string;
  "clientbrowser": string;
  "clientip": string;
  "continent": string;
  "country": string;
  "countrycode": string;
  "groupid": string;
  "loginuser": string;
  "message": string;
  "module": string;
  "organizationid": string;
  "outcome": string;
  "region": string;
  "screen": string;
  "transactionid": string;
  "useraction": string;
  "userfullname": string;
  "userid": string
}


