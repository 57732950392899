<div class="d-flex justify-content-center align-items-center h-100 margin">
  <div class="shadow-effect">
    <div class="p-4 shadow-lg rounded bg-white">
      <span class="d-block mb-5 text-center">
        <img src="assets/img/Triarq_Logo.png" class="img-responsive" width="180" />
      </span>
      <form class="login100-form validate-form" [formGroup]="registerForm">
        <h4 class="text-center text-primary mb-2">
          Recover your account
        </h4>
        <div class="row" [ngClass]="{ 'fade-in-left-sm': bgTrans }">
          <div class="col-md-12">
            <mat-form-field appearance="standard" class="w-100">
              <mat-label>Email Address</mat-label>
              <input autofocus matInput formControlName="email" />
            </mat-form-field>
            <div class="error text-danger ">{{ errorMessage }}</div>
            <div class="success text-success">{{ successMessage }} </div>
            <!-- <label class="success text-success">Click on Continue after the link has been verified</label> -->
          </div>

          <div *ngIf="showRecoverbtn" class="col-md-12">
            <button mat-raised-button type="submit" color="primary" (click)="forgotpassword(registerForm.value)"
              class="float-right mt-2 btn-block">
              Recover
            </button>
          </div>
          <div *ngIf="showRecoverbtn" class="col-md-12">
            <button mat-button class="mt-2" routerLink="/login" queryParamsHandling="preserve">Back</button>
          </div>

          <!-- <button *ngIf="!showRecoverbtn" mat-raised-button routerLink="/login" color="primary"
        class="float-right mt-2 btn-block">
        Continue
      </button> -->
        </div>
      </form>
    </div>
  </div>
</div>