// import { address, alliance } from './facility';
export class practitioner {
  id: string;
  // identifier:string;

  name: fullname = new fullname();
  alliance: alliance = new alliance();
  address: address = new address();
  phone: string;
  emailid: string;
  tin: string;
  npi: string;
  role: string;
  telecom: telecom = new telecom();
  url: string;
  note: string;
  userid: string;
  username: string;
  password: string;
  gender: string;
}
//
export class address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export class alliance {
  id: string;
  display: string;
}


export class fullname {
  firstname: string;
  middlename: string;
  lastname: string;

}


export class telecom {

  phone: string;
  email: string;
  mobile: string;
  homephone: string;

}


