import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from "../core/auth.service";
import { Router } from "@angular/router";
import { UserService } from '../core/user.service';
import { environment } from "../../environments/environment"
import { AuditService } from '../AuditService/audit.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  setSIDURL_myqone: string;
  setSIDURL_qinsights: string;
  setSIDURL_qadmin: string;
  setSIDURL_qonductor: string;
  setSIDURL_obuzz: string;
  setSIDURL_qcollege: string;
  setSIDURL_qbots: string;
  setSIDURL_qpathways: string;
  setSIDURL_qpathways_admin: string;
  setSIDURL_banq: string;
  setSIDURL_webemr: string;
  setSIDURL_patEng: string;
  setSIDURL_bcbsm: string;

  constructor(public authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    public userService: UserService,
    private auditService: AuditService
  ) {
    this.setSIDURL_myqone = environment.myqone_url + "/#/clrSID";
    this.setSIDURL_qinsights = environment.qinsights_url + "/#/clrSID";
    this.setSIDURL_qadmin = environment.qadmin_url + "/#/clrSID";
    this.setSIDURL_qonductor = environment.qonductor_url + "/#/clrSID";
    this.setSIDURL_obuzz = "";
    // environment.obuzz_url + "/#/clrSID";
    this.setSIDURL_qcollege = environment.qcollege_url + "/#/clrSID";
    this.setSIDURL_qbots = ""
    // environment.qbots_url + "/clrSID";

    this.setSIDURL_qpathways = environment.qpathways_url + "/#/clrSID";
    this.setSIDURL_qpathways_admin = environment.qpathways_admin_url + "/#/clrSID";
    this.setSIDURL_banq = environment.banq_url + "/#/clrSID";
    this.setSIDURL_patEng = "";
    //  environment.pat_engagement_url + "/#/clrSID";

    // if (environment.qwebemr_url !== "") {
    //   this.setSIDURL_webemr = environment.qwebemr_url + "/clrSID";
    // }
    // else {
      this.setSIDURL_webemr = "";
    // }

    if (environment.bcbsm_url !== "") {
      this.setSIDURL_bcbsm = environment.bcbsm_url + "/#/clrSID";
    }
    else {
      this.setSIDURL_bcbsm = "";
    }

  }

  ngOnInit() {
    this.logout();

    // console.log("logout success 1 -------- ");
    // this.userService.getBackArrowDisableOfBrowser();
  }

  logout() {
    // if (this.cookieService.check('UID') && this.cookieService.check('AID')) {
      console.log('In logout')

      this.userService.getCurrentUser()
        .then(result => {
          console.log('In getCurrentUser')

          this.auditService.getid(result.uid);
          this.userService.setSessionStorage("username", result.email);
          this.userService.setSessionStorage("fullname", result.displayName);
          this.auditService.callApI("User Logged out Successfully","");

          this.authService.doLogout().then(
            res => {
              //console.log('In doLogout',res)

              this.cookieService.delete('UID');
              this.cookieService.delete('AID');
              //this.location.back();
              setTimeout(() => {
                this.router.navigate(['login'], { queryParamsHandling: 'preserve' });
              }, 3000);

    // console.log("logout success 2 -------- ");
    // this.userService.getBackArrowDisableOfBrowser();
            },
            error => {
              console.log('In doLogout error')

              setTimeout(() => {
                this.router.navigate(['login'], { queryParamsHandling: 'preserve' });
              }, 3000);
            }
          );
        },
          err => {
            this.authService.doLogout().then(
              res => {
                //console.log('In err doLogout',res)

                this.cookieService.delete('UID');
                this.cookieService.delete('AID');
                //this.location.back();
                setTimeout(() => {
                  this.router.navigate(['login'], { queryParamsHandling: 'preserve' });
                }, 3000);
                // console.log("logout success 3 -------- ");
                // this.userService.getBackArrowDisableOfBrowser();
              },
              error => {
                console.log('In err doLogout error')

                setTimeout(() => {
                  this.router.navigate(['login'], { queryParamsHandling: 'preserve' });
                }, 3000);
              }
            );
          }
        );
    // }
    // else {
    //   this.router.navigate(['login'], { queryParamsHandling: 'preserve' });
    //   // console.log("logout success 4 -------- ");
    //   // this.userService.getBackArrowDisableOfBrowser();
    // }
  }
}
