import { Location } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './core/user.service';
import { DuoserviceService } from './Services/duoservice.service';
import { DuoNotificationComponent } from './duo-notification/duo-notification.component';
import { LoginserviceService } from './Services/loginservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public router: Router, private location: Location,
    private userService: UserService , 
    private duoService: DuoserviceService,
    private loginService : LoginserviceService) {
    window.addEventListener("pageshow", function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    });

    if(this.userService.getLocalStorage('isDuoInitiated') === true) {
      if(window.location.pathname.replace('/', '' ) === "duocallback/") {
        var state: string = ""
        var code: string = ""

        state = decodeURIComponent(window.location.search.replace('?' , '').split('&')[0].split('=')[1]);
        code = decodeURIComponent(window.location.search.replace('?' , '').split('&')[1].split('=')[1]);

        const resData = this.userService.getLocalStorage('resData');
        const uid = this.userService.getLocalStorage('uid');

        this.duoService.DuoUserStateByUserid(resData.userid , resData.token).subscribe((res) => {

          if(state === res.duostate){
            //redirect to dashboard
            this.loginService.validateDuoUser( resData , uid);
          } else {
            // Redirect to login if state is not valid
            this.router.navigate(['/login']);
          }
      });
    }

    }
    
  }
  title = 'QAccounts';
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.router.navigate(['/login']);
    console.log("Back pressed in app component")
    this.location.back()
  }


}
