import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
//
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { UserComponent } from "./user/user.component";
import { environment } from "src/environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AuthService } from "./core/auth.service";
import { UserService } from "./core/user.service";
import { AuthGuard } from "./core/auth.guard";
import { UserResolver } from "./user/user.resolver";
import { HttpClientModule } from '@angular/common/http';
import { ProfileupdateComponent } from "./profileupdate/profileupdate.component";
import { SafePipe } from "./user/user.component";
import { RouterModule } from "@angular/router";
import { routes } from "./app-routing.module";
import { CookieService } from "ngx-cookie-service";
import { LogoutComponent } from "./logout/logout.component";
import { MaterialModule } from "./material/material.module";
import { EmailverificationComponent } from './emailverification/emailverification.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { AutoFocusDirective } from './auto-focus.directive';

import { ActivedirectoryComponent } from './activedirectory/activedirectory.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { PhoneloginComponent } from './phonelogin/phonelogin.component';
import { DatePipe } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { DuoNotificationComponent } from './duo-notification/duo-notification.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    UserComponent,
    ProfileupdateComponent,
    SafePipe,
    LogoutComponent,
    ForgotpasswordComponent,
    EmailverificationComponent,
    ResetpasswordComponent,
    AutoFocusDirective,
    ActivedirectoryComponent,
    PhoneloginComponent,
    DuoNotificationComponent

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule,
    ReactiveFormsModule,
    ToastrModule,
    GridModule,
    TextMaskModule,
    DeviceDetectorModule,


    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-center",
      preventDuplicates: true
    }),
  ],
  exports: [RouterModule],
  providers: [AuthService, UserService, UserResolver, AuthGuard, CookieService, DatePipe ],
  bootstrap: [AppComponent]
})
export class AppModule { }