import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DuoserviceService {
  private duoUrl = environment.DuoService + 'duo-init';

  constructor(private http: HttpClient) { }

  InitiateDuo(username: string, redirectionurl: string , token : string , myQoneUserID : string): Observable<any> { 
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      const body = { username, redirectionurl , myQoneUserID};

    return this.http.post<any>(this.duoUrl, body, { headers });
  }

  DuoUserStateByUserid(userid: string, token : string): Observable<any> { 
    var serviceURL = environment.service_url + "DuoUserStateByMyQoneUserid/" + userid ;
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any>(serviceURL, { headers });
  }
}
