<div class="d-flex justify-content-center align-items-center h-100 margin">
    <div class="shadow-effect card-width">
        <div class="pl-4 pt-4 pr-4 pb-3 rounded bg-white box bg-img">
            <span class="d-block mb-3 text-center" *ngIf="isFromMob">
                <img src="assets/img/MHP_Logo.svg" class="img-responsive" width="180" />
            </span>
            <h4 class="text-center text-primary mb-2">
                Provide Personal Information
            </h4>
            <div class="row ">
                <div class="col-md-12 ">
                    <form [formGroup]="form" (submit)="updateUserData(form.value, form.valid)">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="standard" class="w-100">
                                    <mat-label>First Name</mat-label>
                                    <input matInput formControlName="firstname" />
                                    <mat-error *ngIf="form.controls.firstname.touched && form.controls.firstname.errors">
                                        <small class="text-danger">Enter valid first name</small>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="standard" class="w-100">
                                    <mat-label>Last Name</mat-label>

                                    <input matInput formControlName="lastname" />
                                    <mat-error *ngIf="form.controls.firstname.touched && form.controls.firstname.errors">
                                        <small class="text-danger">Enter valid last name</small>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select placeholder="Gender" formControlName="gender">
                                        <mat-option value="male">Male</mat-option>
                                        <mat-option value="female">Female</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="w-100">
                                    <input type="text" matInput placeholder="Date of Birth" formControlName="birthdate" [textMask]="{mask: datemask}" [(ngModel)]="dateModel" [value]="selecteddtDOB" (blur)="checkdate($event.target.value,'dtdob')" />
                                </mat-form-field>
                                <div class="small text-primary" style="margin-top: -15px;">Format: MM/DD/YYYY. E.g 12/30/1980
                                </div>
                            </div>

                            <div *ngIf="!userType || (userType == 'pat' && isFromEmail)" class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-label>Mobile</mat-label>
                                    <input matInput autocomplete="off" type="text" formControlName="mobilenumber" pattern="^(?!0+$)(?:\(?\+\d{1,2}\)?[- ]?|0)?\d{10}$" maxlength="13" placeholder="+1 650-555-1234" />
                                    <mat-error *ngIf="form.controls.mobilenumber.touched && form.controls.mobilenumber.errors">
                                        <small class="text-danger">Enter valid mobile number</small>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="userType == 'pat' && isFromMob" class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-label>Email</mat-label>
                                    <input autofocus autocomplete="off" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" matInput formControlName="email" (blur)="isUserExist($event.target.value)" />
                                    <mat-error *ngIf="form.controls.email.touched && form.controls.email.errors && form.controls.email.errors.alreadyexists">
                                        User with same email already present!</mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="userType != 'pat'" class="col-md-6">
                                <mat-form-field class="w-100">
                                    <mat-select placeholder="User Type" formControlName="nusertypeid">
                                        <mat-option *ngFor="let usertype of response" value="{{ usertype.nusertypeid }}">
                                            {{ usertype.susertype }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="userType != 'pat'" class="col-md-12">
                                <mat-form-field appearance="legacy" class="w-100">
                                    <mat-label>NPI</mat-label>
                                    <input matInput formControlName="snpi" onkeypress="return event.charCode >= 48 && event.charCode <= 57" onpast="return false" maxlength="10" />
                                    <button type="button" mat-icon-button (click)="Search();" data-toggle="modal" data-target=".bd-example-modal-lg" (click)="Search();" title="Search " matSuffix>
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <!-- <mat-error *ngIf="showErr" style="font-size: small;">{{showErrMsg}} !</mat-error> -->

                                <button id="submitBtn" mat-raised-button type="submit" color="primary" [disabled]="!form.valid" class="float-right mt-2 mr-2 justify-content-end">
                                    Submit
                                </button>
                                <!-- <button routerLink="/login" mat-button type="button" class="float-left mt-2 justify-content-end">
              Back
            </button> -->
                            </div>
                            <div class="col-md-12">
                                <div class="text-center">
                                    <span class="text-muted">Powered by</span> <span class="ml-2" style="color: #002743;">TRIAR<span style="color: #e96127;">Q</span> Health</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Search Provider</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeBtn></button>
            </div>
            <div class="modal-body">
                <div class="" *ngIf="!bshow">
                    <form [formGroup]="SearchForm">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="form-group col-4">
                                    <label class="form-label">NPI Number</label>
                                    <input type="text" kendoTextBox class="form-control form-control-sm" formControlName="number" (keyup.enter)="GetData()" onkeypress="return event.charCode >= 48 && event.charCode <= 57" onpast="return false" maxlength="10">
                                </div>
                                <div class="form-group col-4">
                                    <label class="form-label">First Name</label>
                                    <input type="text" kendoTextBox class="form-control form-control-sm" formControlName="first_name" (keyup.enter)="GetData()">
                                </div>
                                <div class="form-group col-4">
                                    <label class="form-label">Last Name</label>
                                    <input type="text" kendoTextBox class="form-control form-control-sm" formControlName="last_name" (keyup.enter)="GetData()">
                                </div>

                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label">Taxonomy Description</label>
                                <input type="text" kendoTextBox class="form-control form-control-sm" formControlName="taxonomy_description" (keyup.enter)="GetData()">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="form-group col-3">
                                    <label class="form-label">City</label>
                                    <input type="text" kendoTextBox class="form-control form-control-sm" formControlName="city" (keyup.enter)="GetData()">
                                </div>
                                <div class="form-group col-4">
                                    <label class="form-label">State</label>
                                    <input type="text" kendoTextBox class="form-control form-control-sm" formControlName="state" (keyup.enter)="GetData()">
                                </div>
                                <div class="form-group col-2">
                                    <label class="form-label">Postal Code</label>
                                    <input type="text" kendoTextBox class="form-control form-control-sm" formControlName="postal_code" (keyup.enter)="GetData()">
                                </div>
                                <div class="form-group col-3">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Window after search -->
                <div *ngIf="bshow">

                    <kendo-grid [data]="OrgList" [pageSize]="pageSize" [skip]="skip" [pageable]="true" [sortable]="{
              allowUnsort: allowUnsort,
              mode: multiple ? 'multiple' : 'single'
            }" [sort]="sort" [selectable]="selectableSettings" (change)="setSelectableSettings()" (pageChange)="pageChange($event)" (sortChange)="sortChange($event)" [loading]="loading" [resizable]="true" (selectionChange)="onSelect($event)">

                        <kendo-grid-checkbox-column [width]="40" class="text-center"></kendo-grid-checkbox-column>
                        <kendo-grid-column [width]="100" field="npi" title="NPI"></kendo-grid-column>
                        <kendo-grid-column field="name" title="Name">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                {{dataItem.basic.firstname}} {{dataItem.basic.middlename}} {{dataItem.basic.lastname}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column [width]="80" field="city" title="City">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                {{dataItem.adresses[0].city}}

                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column [width]="80" field="state" title="State">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                {{dataItem.adresses[0].state}}

                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column [width]="100" field="phone" title="Telephone">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                {{dataItem.adresses[0].telephone}}
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                    <div *ngIf="!bShowList" class="k-icon k-i-loading" style="font-size: 64px; ">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="!bshow" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button *ngIf="!bshow" type="button" class="btn btn-primary" data-dismiss="modal" (click)="GetData()">Search</button>
                <button *ngIf="bshow" type="button" class="btn btn-secondary" (click)="toggle()" data-dismiss="modal">Back</button>
                <button *ngIf="bshow" type="button" class="btn btn-primary" (click)="SaveOrg()" data-dismiss="modal">Select</button>
            </div>
        </div>
    </div>
</div>