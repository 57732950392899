import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { getLogData } from "src/app/shared/getLogData";
import { auditcls } from "./auditLog";
import { DeviceDetectorService } from "ngx-device-detector";
import { UserService } from "../core/user.service";

@Injectable({
  providedIn: "root",
})
export class AuditService {
  obj = new auditcls();
  public ip: string = "";
  public ADuser: string = "";
  public loginerr: string = "";
  public ClientBrowser = "";
  public module: string = "Accounts";
  public screen: string = "Login";
  public message: string = "";
  public useraction: string = "LOGIN";
  username;
  fullname;
  userid;
  country: any;
  continent: any;
  city: any;
  countryCode: any;
  region: any;
  ClientIP;
  deviceinfo;
  application = "QAccounts";
  Message;
  georesult: any;
  public arr: any[] = [];
  countryname;
  countrycode;
  browserCountry;
  Georesult: any;
  countryinfo = [];
  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private userService: UserService
  ) {
    this.getIP();
    // this.getCountryCode();
    this.fullname = this.userService.getSessionStorage("fullname");
    this.ip = this.userService.getSessionStorage("ip");
    this.ADuser = this.userService.getSessionStorage("adLogin");
    this.ClientBrowser = this.deviceInfo(); //getLogData.getbroweser();
    //console.log('this.ClientBrowser', this.ClientBrowser);
    // this.userid = this.userService.getSessionStorage('userid');
    this.loginerr = this.userService.getSessionStorage("loginerr");
    if (this.ADuser === "adlogin")
      this.message = "User Login Successful (Active Directory)";
    else if (this.loginerr === "loginerr")
      this.message = "User Login unsuccessful";
    // console.log("id=", this.userid) Trigger Build
  }
  deviceInfo() {
    this.deviceinfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    //console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    //console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    //console.log(isDesktopDevice);
    const browserInfo =
      "Browser:" +
      this.deviceinfo.browser +
      " \t Version:" +
      this.deviceinfo.browser_version +
      " \t Device: " +
      this.deviceinfo.device +
      "\t OS:" +
      this.deviceinfo.os +
      "\t OS_version:" +
      this.deviceinfo.os_version;
    return browserInfo;
  }
  getCountryCode() {
    this.http
      .get("https://restcountries.eu/rest/v2/all")
      // .map(res3 => res3.json())
      .subscribe((res3: any) => {
        // this.countryinfo = res3;
        //console.log(res3);
        this.userService.setSessionStorage("countryData", res3);
        //console.log(res3, res3[0].callingCodes[0].length);
      });
  }
  getIP() {
    this.http
      .get("https://extreme-ip-lookup.com/json/")
      // .map(res3 => res3.json())
      .subscribe((res3: any) => {
        this.georesult = res3;
        this.arr = res3;
        if (this.georesult === undefined) {
          //console.log("Unable to get geoiplookup from extreme-ip-lookup.com");
        } else {
          if (this.georesult.query !== undefined) {
            this.ClientIP = this.georesult.query;
            this.country = this.georesult.country;
            this.countryCode = this.georesult.countryCode;
            this.continent = this.georesult.continent;
            this.region = this.georesult.region;
            this.city = this.georesult.city;
            //console.log(this.georesult);

            this.userService.setSessionStorage(
              "countryCode",
              this.georesult.countryCode
            );
          }
        }
      });
  }
  getid(id) {
    this.userid = id;
  }
  getname(name) {
    this.fullname = name;
  }
  callApI(para, token) {
    if (token == "") {
      token = this.userService.decryptData(environment.dToken);
    }
    //console.log(para);
    this.username = this.userService.getSessionStorage("username");
    if (para === "New Account Created") {
      this.useraction = "ADD";
      this.screen = "Create Account";
      this.Message = para;
    } else if (para === "Password Recovery Performed") {
      (this.useraction = "UPDATE"), (this.screen = "Recover Account");
      this.Message = para;
    } else if (para === "User Logged out Successfully") {
      this.useraction = "Logout";
      this.screen = "Logout";
      this.Message = para;
    } else if (
      para ===
      "Password Updated  for user " +
        this.userService.getSessionStorage("fullname")
    ) {
      this.useraction = "UPDATE";
      this.screen = "Reset Password";
    } else {
      console.log("para.......");

      this.useraction = "READ";
      this.screen = "Phone Login";
      this.application = "QAccounts";
      this.Message =
        para +
        "[" +
        this.deviceinfo.browser +
        "," +
        this.deviceinfo.browser_version +
        "]";
    }

    this.obj.userfullname = this.fullname ? this.fullname : "";
    this.obj.userid = this.userid ? this.userid : "";
    this.obj.application = this.application;
    this.obj.city = this.city;
    this.obj.clientbrowser = this.ClientBrowser;
    this.obj.clientip = this.ClientIP;
    this.obj.continent = this.continent;
    this.obj.country = this.country;
    this.obj.countrycode = this.countryCode;
    this.obj.loginuser = this.username ? this.username : "";
    this.obj.message = this.Message;
    this.obj.module = this.module;
    this.obj.region = this.region;
    this.obj.screen = this.screen;
    this.obj.useraction = this.useraction;
    this.obj.transactionid = "0";

    //console.log('audit log object', this.obj);
    this.http
      .post<any>(environment.auditUrl + "audit/v1/WriteAuditLog", this.obj, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token),
      })
      .subscribe((res) => {
        //console.log("v1 audit response", res);
      });

    // this.http.get<any>(environment.auditUrl + "audit/WriteAuditLog?" + "application=QAccounts&clientbrowser=" + this.ClientBrowser + "&clientip=" + this.ClientIP + "&loginuser=" + this.username + "&message=" + para + "&module=" + this.module + "&screen=" + this.screen + "&transactionid=" + "" + "&useraction=" + this.useraction + "&country=" + this.country + "&countrycode=" + this.countryCode + "&continent=" + this.continent + "&region=" + this.region + "&city=" + this.city + "" + "&userfullname=" + this.fullname + "&userid=" + this.userid).subscribe(res => {
    //   // console.log(res);
    //   //sessionStorage.removeItem('userid');
    // });
  }
}
