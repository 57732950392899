import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AuthService } from "../core/auth.service";
import { Location } from "@angular/common";
import { UserService } from '../core/user.service';
import { FirebaseUserModel } from '../core/user.model';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../environments/environment"
import { AuditService } from '../AuditService/audit.service';
import { DuoserviceService } from "../Services/duoservice.service";
import { DuoNotificationComponent } from "../duo-notification/duo-notification.component";
import { LoginserviceService } from "../Services/loginservice.service";

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"]
})

export class UserComponent implements OnInit {

  user: FirebaseUserModel = new FirebaseUserModel();
  ispasswordexpired: boolean;
  fireuser: any;
  resetFlag: boolean = true;
  uid: string;
  fire_token: string;
  token: string;
  setSIDURL_testapp: string;
  setSIDURL_myqone: string;
  setSIDURL_qinsights: string;
  setSIDURL_qadmin: string;
  setSIDURL_qonductor: string;
  setSIDURL_obuzz: string;
  setSIDURL_qcollege: string;
  userDetails: any;
  userInfo: any;
  navigate: boolean;
  useSetCookie: boolean;
  returnUrl: string;
  username : string;
  constructor(public authService: AuthService,
    public userService: UserService,
    private duoService: DuoserviceService,
    private loginService : LoginserviceService) {
    this.getUser();
  }

  ngOnInit(): void {
  }

  getUser() {
    this.userService.getCurrentUser()
      .then(res => {
        this.fireuser = res;
        this.uid = this.fireuser.uid;
        this.fire_token = this.fireuser._lat;
        this.username = this.fireuser.email;
        this.userService.getAccessUserDetails(this.uid, this.fire_token).subscribe((resData) => {
          
          this.userService.updateUserAccess(resData.userid, resData.token).subscribe((res) => {
            if(resData.mfaenabled == true && resData.ispasswordexpired == false) {
              this.userService.setLocalStorage('continuePath' , window.location.hash.split('=')[1]??'');
              this.duoService.InitiateDuo(this.username, window.location.origin.toString() + "/duocallback/", resData.token , resData.userid).subscribe({
                next: (data: any) => {
                  this.userService.setLocalStorage('uid' , this.uid);
                  this.userService.setLocalStorage('resData' , resData);
                  this.userService.setLocalStorage('isDuoInitiated' , true);
                  window.open(data.promptUri, "_self");
                },
                error: (error: any) => { },
                complete: () => {
                }
              });
            } 
            else {
              this.loginService.validateDuoUser(resData , this.uid);
            }
          });
        })
      }, err => console.log(err))
  }

}
