import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { customValidator } from '../shared/customValidator';
import { ProfileService } from '../Services/profile/profile.service';
import { user } from '../Entities/user';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../core/user.service';
import { log } from 'util';
import { AuditService } from '../AuditService/audit.service';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ["./resetpassword.component.css"]
})
export class ResetpasswordComponent implements OnInit {
  resetForm: FormGroup;

  userid: string;
  token: string;
  algokey: string = "glotest123456789";
  constructor(
    public profileService: ProfileService,
    private auditService: AuditService,
    public authService: AuthService,
    private router: Router,
    public userService: UserService,
    private cookieService: CookieService,
    private fb: FormBuilder,
    private activatedroute: ActivatedRoute,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.activatedroute.params.subscribe((param) => {
      this.userid = param['userid'];
      this.token = param['token'];

      //   console.log(this.userid, this.token);
    },
      (ex) => {
        this.userid = "";
      })

  }

  createForm() {
    this.resetForm = this.fb.group({
      olduser_pass: ['', Validators.required],
      userpass: ['',

        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          customValidator.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          customValidator.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          customValidator.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          customValidator.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8),
          customValidator.cannotContainspace1
        ])

      ],
      reuser_pass: ['', Validators.required]
    }, {
      validator:
        Validators.compose
          ([
            customValidator.userpasswordsShouldMatch2,
            customValidator.oldandnewpasswordcannotbesame
          ])
    });
  }
  resetPassword(data) {

    var base64Key = CryptoJS.enc.Utf8.parse(this.algokey);
    var iv = CryptoJS.enc.Utf8.parse(this.algokey);

    var source_string = this.resetForm.value.olduser_pass; //console.log("currentpass", source_string);
    var encrypted = CryptoJS.AES.encrypt(
      source_string,
      base64Key,
      {
        keySize: 16,
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    var source_string2 = this.resetForm.value.userpass;// console.log("currentpass", source_string2);
    var newencryptedpass = CryptoJS.AES.encrypt(
      source_string2,
      base64Key,
      {
        keySize: 16,
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    let user: { currentpassword: string, id: string, newpassword: string } = {
      currentpassword: String(encrypted), id: String(this.userid), newpassword: String(newencryptedpass)
    };
    //  console.log("user", user);
    this.profileService.resetPassword(user, this.token).subscribe((res: any) => {
      //console.log("reset response", res);
      if (res.message == "Invalid Password") {
        this.resetForm.controls.olduser_pass.setErrors({
          oldpasswordinvalid: true
        });
      }
      else if (res.message == "Password Updated") {
        this.auditService.getname(this.userService.getSessionStorage('fullname'));
        this.auditService.getid(this.userid);
        this.auditService.callApI("Password Updated  for user " + this.userService.getSessionStorage('fullname'),this.token);
        this.userService.setSessionStorage('resetFlag', 'true');
        this.logout();
      }
    })

    // let seq = this.api.post('users/changepassword', user);
    // seq
    //   //   .map(res => res.json())
    //   .subscribe(res => {
    //     // console.log(res);
    //     this.result = res;
    //     console.log("change password", this.result);

    //     if (this.result.message == "Invalid Password") {
    //       this.frmsignup.controls.olduser_pass.setErrors({
    //         oldpasswordinvalid: true
    //       });
    //     }
    //     else if (this.result.message == "Password Updated") {
    //       this.toastr.info('Password Updated.');
    //       this.globalSettings.setLoginStatus(false);
    //       this.globalSettings.clearuserparameters();
    //       this._Router.navigate(['/landing']);
    //     }
    //     else {
    //       alert('Invalid Case');
    //     }
    //   }, err => {
    //     this.toastr.error('Error in Service Call');
    //   });



  }
  encryptPassword(password: string) {
    var base64Key = CryptoJS.enc.Utf8.parse(this.algokey);
    var iv = CryptoJS.enc.Utf8.parse(this.algokey);
    var newencryptedpass = CryptoJS.AES.encrypt(
      password,
      base64Key,
      {
        keySize: 16,
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return newencryptedpass;
  }
  logout() {
    // console.log("in logout");

    this.authService.doLogout().then(
      res => {
        this.cookieService.delete('UID');
        this.cookieService.delete('AID');
        this.router.navigate(['login'], { queryParamsHandling: 'preserve' });
      },
      error => {
        console.log("Logout error", error);
      }
    );
  }
}
