<div class="d-flex justify-content-center align-items-center h-100 margin">
    <div class="shadow-effect w-530">
        <div class="p-4 shadow-lg rounded bg-white">
            <span class="d-flex justify-content-center mb-5">
                <img src="assets/img/Triarq_Logo.png" alt="" width="180">
            </span>

            <form [formGroup]="resetForm">
                <h4 class="text-center text-primary mb-2">Change Password</h4>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="standard" class="w-100">
                            <mat-label>Current Password</mat-label>
                            <input autofocus matInput type="password" formControlName="olduser_pass" #olduser_pass />

                            <mat-error *ngIf="resetForm.controls.olduser_pass.touched && resetForm.controls.olduser_pass.errors ">
                                <small *ngIf="resetForm.controls.olduser_pass.errors?.required" class="text-danger">Enter
                                    Password</small>
                            </mat-error>

                            <mat-error *ngIf="resetForm.controls.olduser_pass.errors && resetForm.controls.olduser_pass.errors.oldpasswordinvalid">

                                <small class="text-danger"> Old password does not match</small>
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-md-12">
                        <mat-form-field appearance="standard" class="w-100">
                            <mat-label>New Password</mat-label>
                            <input autofocus matInput type="password" formControlName="userpass" #userpass />

                        </mat-form-field>

                    </div>

                    <div *ngIf="resetForm.controls.userpass.touched && resetForm.controls.userpass.errors">
                        <small class="col mb-0 text-danger">Your password must have:</small>
                        <label class=" col mb-0" *ngIf="resetForm.controls.userpass.errors && resetForm.controls['userpass'].errors?.required || resetForm.controls['userpass'].errors.minlength" [ngClass]="resetForm.controls['userpass'].errors?.required  || resetForm.controls['userpass'].errors.minlength  ? 'text-danger' : 'text-success'">
                            <small>
                                8 or more characters</small>
                        </label>
                        <label class="col mb-0" *ngIf="resetForm.controls.userpass.errors &&  resetForm.controls['userpass'].errors?.required || resetForm.controls['userpass'].errors.hasNumber" [ngClass]="resetForm.controls['userpass'].errors?.required || resetForm.controls['userpass'].errors.hasNumber ? 'text-danger' : 'text-danger'">

                            <small> at least one number </small>
                        </label>
                        <label class="col mb-0" *ngIf="resetForm.controls.userpass.errors && resetForm.controls['userpass'].errors?.required  || resetForm.controls['userpass'].errors.hasCapitalCase || resetForm.controls['userpass'].errors.hasSmallCase" [ngClass]="resetForm.controls['userpass'].errors?.required  || resetForm.controls['userpass'].errors.hasCapitalCase ? 'text-danger' : 'text-danger'">

                            <small> upper and lower case letters </small>
                        </label>

                        <label class="col mb-0" *ngIf="resetForm.controls.userpass.errors && resetForm.controls['userpass'].errors?.required || resetForm.controls['userpass'].errors.hasSpecialCharacters" [ngClass]="resetForm.controls['userpass'].errors?.required  || resetForm.controls['userpass'].errors.hasSpecialCharacters ? 'text-danger' : 'text-danger'">

                            <small> at least one special character </small>
                        </label>
                        <label class="col mb-0" *ngIf="resetForm.controls.userpass.errors && resetForm.controls['userpass'].errors?.required || resetForm.controls['userpass'].errors.cannotContainspace1" [ngClass]="resetForm.controls['userpass'].errors?.required  || resetForm.controls['userpass'].errors.cannotContainspace ? 'text-danger' : 'text-danger'">

                            <small> can not contain space </small>
                        </label>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="standard" class="w-100" style="padding-bottom: 0em;">
                            <mat-label>Retype Password</mat-label>
                            <input autofocus matInput type="password" formControlName="reuser_pass" #reuser_pass />
                        </mat-form-field>
                        <mat-error *ngIf="resetForm.controls.reuser_pass.touched && resetForm.controls.reuser_pass.errors">
                            <small *ngIf="resetForm.controls.reuser_pass.errors.required" class="text-danger">Confirm
                                the
                                password</small>
                        </mat-error>
                        <mat-error>
                            <small *ngIf="resetForm.controls.reuser_pass.touched && resetForm.errors && resetForm.errors.userpasswordsShouldMatch2" class="text-danger">
                                Passwords don't match.</small>
                        </mat-error>
                        <div>
                            <mat-label> <small class="text-danger">Either this is your first login or your password has
                                    expired.
                                    Please change your password to continue. You cannot set your current password as
                                    your new
                                    password.
                                </small> </mat-label>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <button mat-raised-button type="submit" color="primary" [disabled]="!resetForm.valid" class="float-right mt-2 btn-block" (click)="resetPassword(resetForm.value)">
                            Change Password
                        </button>
                    </div>
                    <div class="col-md-12">
                        <button mat-raised-button type="submit" color="primary" class="float-right mt-2 btn-block" (click)="logout()">
                            Logout
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>