<div class="d-flex justify-content-center align-items-center h-100 margin">
    <div class="shadow-effect">
        <div class="p-4 shadow-lg rounded bg-white w-530" *ngIf="mode=='resetPassword'">
            <span class="d-flex justify-content-center mb-5">
                <img src="assets/img/Triarq_Logo.png" width="180" />
            </span>
            <form class="login100-form validate-form" [formGroup]="resetForm" *ngIf="resetFlag==false">
                <h4 class="text-center text-primary mb-4">
                    Reset your password
                </h4>
                <span>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label>Password</mat-label>
                                <input autofocus matInput formControlName="password"
                                    [type]="hide1 ? 'password' : 'text'" />
                                <mat-icon class="ui=" matSuffix (click)="hide1 = !hide1" class="hide-icon"
                                    [ngClass]="{'eye-color': hide1}">
                                    {{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <!-- <mat-error>
                            <div class="mb-3">
                                Password should be of at least 8 characters with one capital letter, one small
                                letter, one numeric
                                digit and one special
                                character...
                            </div>
                        </mat-error> -->
                            </mat-form-field>
                            <div *ngIf="resetForm.controls.password.touched && resetForm.controls.password.errors">
                                <small class="col mb-0 text-danger">Your password must have:</small>
                                <label class=" col mb-0"
                                    *ngIf="resetForm.controls.password.errors && resetForm.controls['password'].errors?.required || resetForm.controls['password'].errors.minlength"
                                    [ngClass]="resetForm.controls['password'].errors?.required  || resetForm.controls['password'].errors.minlength  ? 'text-danger' : 'text-success'">
                                    <small>
                                        8 or more characters</small>
                                </label>
                                <label class=" col mb-0"
                                    *ngIf="resetForm.controls.password.errors && resetForm.controls['password'].errors?.required || resetForm.controls['password'].errors.maxlength"
                                    [ngClass]="resetForm.controls['password'].errors?.required  || resetForm.controls['password'].errors.maxlength  ? 'text-danger' : 'text-success'">
                                    <small>
                                        20 or less than 20 characters</small>
                                </label>
                                <label class="col mb-0"
                                    *ngIf="resetForm.controls.password.errors &&  resetForm.controls['password'].errors?.required || resetForm.controls['password'].errors.hasNumber"
                                    [ngClass]="resetForm.controls['password'].errors?.required || resetForm.controls['password'].errors.hasNumber ? 'text-danger' : 'text-success'">

                                    <small> at least one number </small>
                                </label>
                                <label class="col mb-0"
                                    *ngIf="resetForm.controls.password.errors && resetForm.controls['password'].errors?.required  || resetForm.controls['password'].errors.hasCapitalCase || resetForm.controls['password'].errors.hasSmallCase"
                                    [ngClass]="resetForm.controls['password'].errors?.required  || resetForm.controls['password'].errors.hasCapitalCase ? 'text-danger' : 'text-danger'">

                                    <small> upper and lower case letters </small>
                                </label>
                                <!-- <label class="col"
                            *ngIf="resetForm.controls.userpass.errors && resetForm.controls['userpass'].errors?.required  || resetForm.controls['userpass'].errors.hasSmallCase"
                            [ngClass]="resetForm.controls['userpass'].errors?.required  || resetForm.controls['userpass'].errors.hasSmallCase ? 'text-danger' : 'text-success'">
        
                            <small> Must contain at least 1 Letter in Small Case </small>
                        </label> -->
                                <label class="col mb-0"
                                    *ngIf="resetForm.controls.password.errors && resetForm.controls['password'].errors?.required || resetForm.controls['password'].errors.hasSpecialCharacters"
                                    [ngClass]="resetForm.controls['password'].errors?.required  || resetForm.controls['password'].errors.hasSpecialCharacters ? 'text-danger' : 'text-success'">

                                    <small> at least one special character </small>
                                </label>
                                <label class="col mb-0"
                                    *ngIf="resetForm.controls.password.errors && resetForm.controls['password'].errors?.required || resetForm.controls['password'].errors.cannotContainspace1"
                                    [ngClass]="resetForm.controls['password'].errors?.required  || resetForm.controls['password'].errors.cannotContainspace ? 'text-danger' : 'text-success'">

                                    <small> can not contain space </small>
                                </label>
                            </div>

                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label>Confirm Password</mat-label>
                                <input autofocus matInput formControlName="cpassword"
                                    [type]="hide ? 'password' : 'text'" />
                                <mat-icon class="ui=" matSuffix (click)="hide = !hide" class="hide-icon"
                                    [ngClass]="{'eye-color': hide}">
                                    {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </mat-form-field>
                            <small
                                *ngIf="resetForm.controls.cpassword.touched && resetForm.errors && resetForm.errors.passwordsShouldMatch"
                                class="text-danger">
                                Passwords don't match.</small>
                        </div>
                        <div class="col-md-12">
                            <button *ngIf="resetFlag==false" mat-raised-button type="submit" color="primary"
                                [disabled]="!resetForm.valid" (click)="resetpassword(resetForm.value)"
                                class="float-right mt-2 btn-block">
                                Save
                            </button>
                        </div>
                    </div>
                </span>
            </form>
            <!-- <h4 class="text-center text-primary mb-4"> -->
            <label class="error">{{ errorMessage }}</label>
            <label class="success">{{ successMessage }}</label>
            <!-- </h4> -->
            <span *ngIf="resetFlag==true"> <button mat-raised-button type="submit" color="primary"
                    class="mt-2 btn-block" (click)="continue()">
                    Continue
                </button>
            </span>


        </div>
        <div class="p-4 shadow-lg rounded bg-white" *ngIf="mode=='verifyEmail'">
            <span class="d-flex justify-content-center mb-5">
                <img src="assets/img/Triarq_Logo.png" width="180" />
            </span>
            <form class="login100-form validate-form">
                <!-- <h4 class="text-center text-primary mb-4"> -->
                <label class="error">{{ errorMessage }}</label>
                <label class="success">{{ successMessage }}</label>
                <!-- </h4> -->
                <!-- <p class="text-center">You can now sign in with your new account</p> -->
                <button mat-raised-button color="primary" class="mt-2 btn-block" (click)="continue()">
                    Continue
                </button>
            </form>

        </div>
    </div>
</div>