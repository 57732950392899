import { Component, OnInit, OnDestroy, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { user } from '../Entities/user';
import { ProfileService } from '../Services/profile/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
//import { DatePipe } from '@angular/common';
import { UserService } from '../core/user.service';
import { Logs } from 'selenium-webdriver';
import { log } from 'util';
import { customValidator } from '../shared/customValidator';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { practitioner } from './practitioner';
import { Observable } from 'rxjs';
import { AuditService } from '../AuditService/audit.service';
import { PatientUserModel } from '../Entities/PatientUserModel';
import { environment } from 'src/environments/environment';
import { TaskModel } from '../Entities/TaskModel';
import { DatePipe } from '@angular/common';
import { SortDescriptor } from '@progress/kendo-data-query';
// const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.css']
})
export class ProfileupdateComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  //  @ViewChild('closeBtn') closeBtn: ElementRef;
  public gridData: GridDataResult;
  public itemspract: any[];
  SearchForm: FormGroup;
  bShowList: boolean = false;
  public bshow: boolean = false;
  public pageSize = 20;
  pagenumber = 0;
  public loading = false;
  public allowUnsort = true;
  public skip = 0;
  selectableSettings: any
  public checkboxOnly = false;
  public multiple = false;
  public mode = 'single';
  public sort: SortDescriptor[] = [{
    field: 'firstname',
    dir: 'asc'
  }];
  list: Observable<practitioner[]>;
  public items: any[];
  public OrgList: GridDataResult;
  exception: any; practdata: any;
  public dateModel = '';
  public datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  selecteddtDOB = "MM/DD/YYYY";
  showErr: boolean = false;
  showErrMsg = "";


  SelFac: any;


  form: FormGroup;
  userid: string;
  token: any;
  userInfo: any;
  fireuser: any;
  displayname: string;
  email: string;

  public susertype: any[] = [];
  public response: any[] = [];

  userType: string;
  isFromMob: boolean;
  isFromEmail: boolean;
  mobile: string;

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    private router: Router,
    private profileService: ProfileService,
    private activatedroute: ActivatedRoute,
    private auditService: AuditService,
    private datePipe: DatePipe
    // obj = new practitioner();
  ) {
    this.SearchForm = fb.group({
      number: new FormControl(""),
      first_name: new FormControl(""),
      last_name: new FormControl(""),
      taxonomy_description: new FormControl(""),
      city: new FormControl(""),
      state: new FormControl(""),
      postal_code: new FormControl(""),
      addtp: new FormControl(""),
      email: new FormControl(""),
      mobile: new FormControl(""),
      tin: new FormControl("")
    })
  }

  ngOnInit() {
    this.activatedroute.params.subscribe((param) => {
      this.userid = param['userid'];
      this.token = param['token'];
      this.activatedroute.queryParams.subscribe((qParam) => {
        //console.log('qParam.src)', qParam.src);
        if (qParam && qParam.src) {
          if (qParam.src === 'mob')
            this.isFromMob = true;
          else if (qParam.src === 'email')
            this.isFromEmail = true;
        }
        if (qParam && qParam.utyp) {
          this.userType = qParam.utyp;
        }
      })
    },
      (ex) => {
        this.userid = "";
      })

    this.form = this.fb.group({
      firstname: ["", [Validators.required, customValidator.Namevalidation]],
      lastname: ["", [Validators.required, customValidator.Namevalidation]],
      // lastname: ["", [Validators.required]],
      birthdate: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      mobilenumber: [""],
      nusertypeid: [""],
      snpi: ["", [Validators.pattern('^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$$')]],
      email: ['', [Validators.email, customValidator.cannotContainspace]]
    });

    this.userInfo = JSON.parse(this.userService.getSessionStorage('googleUserInfo'));

    //console.log("User Profile: " + this.userInfo.additionalUserInfo.profile.given_name);

    this.userService.getCurrentUser()
      .then(res => {
        this.fireuser = res;

        if (res.phoneNumber) {
          this.mobile = res.phoneNumber;
          this.form.patchValue({
            mobilenumber: res.phoneNumber
          })
        }
        if (this.fireuser.email) {
          this.email = this.fireuser.email;
          this.form.patchValue({
            email: this.fireuser.email
          })
          if (this.userType == undefined && this.isFromMob == undefined)
            console.log('userType');
          else
            this.isUserExist(this.fireuser.email);
        }
        // console.log("fireuser", this.fireuser);
        //console.log ("Step 7")
        this.displayname = this.fireuser.displayName;

        var splittedname;
        if (this.displayname != null) {
          splittedname = this.displayname.split(" ");
          //console.log("Splitted Name: " + splittedname[0]);
          //console.log("email ", this.email)

          this.form.patchValue({
            firstname: splittedname[0],
            lastname: splittedname[1]
          })
        }
        //check display name null or not
        if (this.displayname != null)
          splittedname = this.displayname.split(" ");  //change do
        else splittedname = "";
        // console.log("Splitted Name: " + splittedname[0]);
        // console.log("email ", this.email)

        this.form.patchValue({
          firstname: splittedname[0],
          lastname: splittedname[1]  //change do
        })


      })
      ;

    // if(this.userInfo){
    //   this.form.patchValue({
    //     firstname:this.userInfo.additionalUserInfo.profile.given_name,
    //     lastname:this.userInfo.additionalUserInfo.profile.family_name
    //   })
    // }

    this.profileService.getUserType(this.token).subscribe((res: any) => {
      this.response = res;
      res.forEach(type => {
        this.susertype.push(type.susertype);
      });

      if (this.userType === 'pat') {
        var arr = res.filter((m) => m.susertypecode.toLowerCase().indexOf(this.userType.toLowerCase()) !== -1)
        this.form.patchValue({
          nusertypeid: arr[0],
        })
        //console.log(this.form.get('nusertypeid').value);

      }
    })

  }
  // validateNumber(event: any) {
  //   // console.log("number :-", phoneUtil.isValidNumber(phoneUtil.parse((event.target.value))));
  //   if (event.target.value == "") { }
  //   else if (phoneUtil.isValidNumber(phoneUtil.parse((event.target.value))) == false) {
  //     this.form.controls.mobilenumber.setErrors({
  //       invalidmobile: true
  //     });
  //   }

  // }

  ngOnDestroy() {

  }

  ngOnChanges() {

  }

  updateUserData(data, valid: boolean) {

    if (valid) {
      if (this.userType === 'pat') {
        console.log('pathways');

        this.savePatientUser(data);
      }
      else {
        let obj = new user();
        obj.userid = this.userid;
        obj.email = this.email;
        obj.firstname = data.firstname;
        obj.lastname = data.lastname;
        obj.birthdate = this.datePipe.transform(data.birthdate, "yyyy-MM-dd");//data.birthdate;
        obj.gender = data.gender;
        obj.mobilenumber = data.mobilenumber;
        obj.nusertypeid = data.nusertypeid;
        obj.snpi = data.snpi;

        // console.log("object", obj);
        // obj.birthdate = this.datePipe.transform(data.birthdate, "MM/dd/yyyy");

        this.profileService.update(obj, this.token).subscribe((res) => {
          //console.log("update response", res);
          this.router.navigate(["/user"], { queryParamsHandling: 'preserve' });
          this.auditService.getid(this.userid); this.auditService.getname(data.firstname + " " + data.lastname);
          // localStorage.setItem("adLogin", "adlogin");
          this.auditService.callApI("User Prpfile updated","");
        })
      }
    }
  }

  // func to save patient user
  savePatientUser(data) {

    let obj = new PatientUserModel();
    obj.userid = (this.isFromEmail) ? this.userid : '';
    obj.email = (data.email) ? (data.email) : '';
    obj.firstname = data.firstname;
    obj.lastname = data.lastname;
    obj.birthdate = this.convertDatetoString(data.birthdate, "qoresave");
    obj.gender = data.gender;
    obj.mobilenumber = (data.mobilenumber) ? data.mobilenumber : '';
    obj.nusertypeid = (data.nusertypeid) ? (data.nusertypeid.nusertypeid) : '2';
    obj.isprofileupdated = true;
    obj.isactive = true;
    obj.userName = (data.email) ? (data.email) : '';
    obj.mappingid = (this.isFromEmail) ? this.fireuser.uid : this.userid;
    // obj.userPass=sessionStorage.getItem('userPass');
    //console.log('savePatientUser', obj);
    if (this.isFromEmail) {
      this.profileService.update(obj, this.token).subscribe((res) => {
        //console.log('update', res);
        // this.assignAssessment(this.userid);
        this.router.navigate(["/user"], {
          queryParams: {
            continue: environment.pat_engagement_web
          }
        });

        this.auditService.getid(this.userid);
        this.auditService.getname(data.firstname + " " + data.lastname);
        this.auditService.callApI("User Profile updated","");
      })
    }
    else if (this.isFromMob) {
      this.profileService.savePatientUser(obj, this.token).subscribe((response) => {
        //console.log('update-------', response);
        // if (response.message === "Username Exist") {
        //   console.log(response.message );
        // }
        if (response.message === "Record Added Successfully") {
          this.showErr = false;
          // this.assignAssessment(response.id);

          this.router.navigate(["/user"], {
            queryParams: {
              continue: environment.pat_engagement_web
            }
          });
          this.auditService.getid(this.userid); this.auditService.getname(data.firstname + " " + data.lastname);
          this.auditService.callApI("User Profile updated","");
        }
        // if (response.message === "Mobile number already exist")
        else {
          this.showErr = true; this.showErrMsg = response.message;
          //console.log(response.message);
        }

      })
    }
  }

  // serach by npi
  Search() {
    this.SearchForm.reset();
    this.itemspract = [];
    this.loadOrgList();
    this.bShowList = false;
    this.bshow = false;
    this.SearchForm.patchValue({
      number: this.form.value.snpi
    })
  }
  convertDatetoString(date, patientType) {
    if (patientType == 'qore')
      return date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0];
    else if (patientType == 'qoresave')
      return date.split('/')[2] + '-' + date.split('/')[0] + '-' + date.split('/')[1];

  }
  GetData() {

    let npi: string = this.SearchForm.get("number").value;
    this.bshow = true;

    let first_name = this.SearchForm.get("first_name").value;
    let last_name = this.SearchForm.get("last_name").value;
    let city = this.SearchForm.get("city").value;
    let taxonomy = this.SearchForm.get("taxonomy_description").value;
    let postalcode = this.SearchForm.get("postal_code").value;
    let state = this.SearchForm.get("state").value;

    let para: { firstname: string, lastname: string, npi: string, postalCode: string, city: string, state: string, taxonomy: string, pagenumber: number, size: number, sortby: string } = {
      firstname: first_name, lastname: last_name, npi: npi, postalCode: postalcode, city: city, taxonomy: taxonomy, state: state, pagenumber: 0, size: 20, sortby: 'logicalid'
    }
    // get practioner by npi
    this.profileService.getProviderByNPI(para).subscribe(res => {
      if (res['result'] || res['result'].length != 0) {

        this.itemspract = res['result'];
        this.loadOrgList();
      } else {
        this.itemspract = [];
        this.loadOrgList();
      }
      this.bShowList = true;
    },
      (ex) => {
        this.bShowList = true;
      });
  }

  private loadOrgList(): void {
    this.OrgList = {
      data: this.itemspract.slice(this.skip, this.skip + this.pageSize),
      total: this.itemspract.length
    };
  }
  onSelect(event) {
    this.SelFac = event.selectedRows[0].dataItem;
    //console.log(' this.SelFac', this.SelFac,this.SelFac.mobile)
  }
  public pageChangePract(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadOrgList();
  }
  toggle() {
    this.bshow = !this.bshow;
  }
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode
    };
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (this.OrgList)
      this.loadOrgList();

  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pagenumber = (event.skip / event.take);
    this.pageSize = event.take;
    this.loadOrgList();

  }

  SaveOrg() {
    let obj = new practitioner();
    obj.name.firstname = this.SelFac.basic.firstname;
    obj.name.middlename = this.SelFac.basic.middlename;
    obj.name.lastname = this.SelFac.basic.lastname;
    obj.gender=this.SelFac.basic.gender=='M'? 'male':'female';
    obj.address.address1 = this.SelFac.adresses[0].address1;
    obj.address.address2 = this.SelFac.adresses[0].address2;
    obj.address.city = this.SelFac.adresses[0].city;
    obj.address.state = this.SelFac.adresses[0].state;
    obj.address.zip = this.SelFac.adresses.zip;
    obj.telecom.phone = this.SelFac.adresses[0].telephone;
    obj.telecom.email = this.SelFac.email;
    obj.npi = this.SelFac.npi;

    this.form.patchValue({
      firstname: obj.name.firstname,
      username: obj.username,
      lastname: obj.name.lastname,
      mobilenumber: obj.telecom.mobile,
      email: obj.emailid,
      gender: obj.gender,
      snpi: obj.npi,
    })
    this.bshow = false;
    this.closeBtn.nativeElement.click();
  }
  isUserExist(Email) {
    //console.log('email', Email);
    try {
      this.profileService.isUserExists(Email).subscribe(res => {
        //console.log('res', res);
        if (res.message === 'User Found') {
          var element = <HTMLInputElement>document.getElementById("submitBtn");
          element.disabled = true;
          this.form.controls.email.setErrors({
            alreadyexists: true
          });
        }
      }), error => console.log('oops', error);
    } catch (err) {
      console.log('eroror', err);

    }

  }
  isLeap(year) {
    // Return true if year
    // is a multiple pf 4 and
    // not multiple of 100.
    // OR year is multiple of 400.
    return (((year % 4 == 0) &&
      (year % 100 != 0)) ||
      (year % 400 == 0));
  }
  checkdate(dob, type) {

    try {
      //console.log('DOB in Checkdate', dob);
      let DD = dob.split('/')[1];
      let MM = dob.split('/')[0];
      let YYYY = dob.split('/')[2];
      let invalid = 'valid';
      if (MM.indexOf('02') != -1) {
        if (this.isLeap(YYYY))
          DD <= 29 ? invalid = 'valid' : invalid = 'invalid';
        else DD <= 28 ? invalid = 'valid' : invalid = 'invalid';
      }
      else if (MM.indexOf('04') != -1 || MM.indexOf('06') != -1 || MM.indexOf('09') != -1 || MM.indexOf('11') != -1)
        DD <= 30 ? invalid = 'valid' : invalid = 'invalid';

      // if (((DD > 31 || DD == 0) || (MM > 12 || MM == 0))) {
      if (((DD > 31 || DD == 0 || invalid == 'invalid') || (MM > 12 || MM == 0)) || YYYY.indexOf('_') != -1 || YYYY < 1900) {
        this.form.controls.birthdate.setErrors({
        });
      }
    } catch (err) {
    }
  }
}
