export class PatientUserModel {
    userid: string;
    firstname: string;
    lastname: string;
    mobilenumber: string;
    homephone: string;
    email: string;
    birthdate: string;
    gender: string;
    isactive: true;
    isclientadmin: boolean;
    issuperadmin: boolean;
    usercode: string;
    usertype: string;
    mappingid: string;
    nusertypeid: number;
    isprofileupdated: true;
    qpathwaysid: string;
    snpi: string;
    publishedby: string;
    updatedby: string;
    url: string;
    phone: string;
    fax: string;
    isemailverified: true;
    retiredmobilenumber: string;
    designation: string;
    middlename: string;
    userName: string;
    userPass: string;
    groups: any[] = [];
    roles: any[] = [];
    sendemail: boolean;
    usernm: string;
    changeatnextlogon: true;
    usersprovider: [
        {
            npi: string;
            providerid: string;
            providername: string;
            published: string;
            publishedby: string;
            updated: string;
            updatedby: string;
            userid: string;
            usersproviderid: number;
        }
    ]
}