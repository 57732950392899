import { FormGroup, FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class customValidator {
    static passwordsShouldMatch(group: FormGroup) {
        var Password = group.controls.password.value;
        var confirmPassword = group.controls.cpassword.value;
        // If either of these fields is empty, the validation 
        // will be bypassed. We expect the required validator to be 
        // applied first. 
        if (Password == '' || confirmPassword == '')
            return null;

        if (Password != confirmPassword)
            return { passwordsShouldMatch: true };
        else return null;


    }
    static cannotContainspace1(ctrl: FormControl) {

        if (String(ctrl.value).indexOf(' ') >= 0) {
            // console.log(String(ctrl.value).indexOf(' '));
            return { cannotContainspace1: true };
        }
        else {
            return null;
        }
    }
    static oldandnewpasswordcannotbesame(group: FormGroup) {
        var oldpassowrd = group.controls.olduser_pass.value;
        var newpassword = group.controls.userpass.value;


        if (oldpassowrd == '' || newpassword == '') {
            return null;
        }

        if (oldpassowrd === newpassword) {
            return { oldandnewpasswordcannotbesame: true };
        }

        return null;
    }
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                // if control is empty return no error
                return null;
            }

            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);

            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
        };
    }

    static userpasswordsShouldMatch2(group: FormGroup) {
        var newPassword = group.controls.userpass.value;
        var confirmPassword = group.controls.reuser_pass.value;
        // If either of these fields is empty, the validation 
        // will be bypassed. We expect the required validator to be 
        // applied first. 
        if (newPassword == '' || confirmPassword == '')
            return null;

        if (newPassword != confirmPassword)
            return { userpasswordsShouldMatch2: true };

        return null;
    }
    static strongPasswordValidation(control: FormControl) {
        var regEx = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9/.\/a-zA-Z!"#$%&'*+,-./:;<=>?@[\]^_`{|}~*\\()]*$/;
        var valid = regEx.test(control.value);
        return valid ? null : { strongPasswordValidation: true };
    }
    static cannotContainspace(ctrl: FormControl) {
        if (String(ctrl.value).indexOf(' ') == 0) {
            //console.log(String(ctrl.value).indexOf(' '));
            return { cannotContainspace: true };
        }
        else {
            return null;
        }
    }
    static Namevalidation(control: FormControl) {
        //var regEx = /[^a-zA-Z][0-9]*$/;
        var regEx = /^[a-zA-Z_ ][0-9a-zA-Z_ ]*$/
        var valid = regEx.test(control.value);
        return valid ? null : { Namevalidation: true };
    }
    static mobilevalidation(control: FormControl) {
        //var regEx = /[^a-zA-Z][0-9]*$/;
        var regEx = /^(?!0+$)(?:\(?\+\d{1,3}\)?[- ]?|0)?\d{11,12}$/
        var valid = regEx.test(control.value);
        return valid ? null : { mobilevalidation: true };
    }
}