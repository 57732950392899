<div class="d-flex justify-content-center align-items-center h-100 margin">

  <div class="shadow-effect">

    <div class="rounded bg-white box w-530">
      <div class="p-4">
        <span class="d-block mb-5 text-center">
          <img src="assets/img/Triarq_Logo.png" class="img-responsive" width="180" />
        </span>
        <div id="recaptcha-container"> </div>
        <form class="login100-form validate-form" [formGroup]="loginForm">
          <h4 class="text-center text-primary mb-2" *ngIf="viewtype == 'email' || viewtype == 'pass'">
            Sign in to your account
          </h4>
          <!--Trigger Build (blur)="validateNumber($event)" -->
          <span *ngIf="viewtype == 'email'">
            <div class="row" [ngClass]="{ 'fade-in-left-sm': bgTrans }">
              <div class="col-md-12">

                <mat-form-field appearance="standard" class="w-100">
                  <mat-label>Email Address</mat-label>
                  <input matInput formControlName="email" autocomplete="off" />
                </mat-form-field>
                <label class="error">
                  {{ errorMessage }} </label>
                <label class="success">{{ successMessage1 }}</label>
              </div>
              <div class="col-md-6">
                <div class="text-left">
                  <p color="primary" class="text-left text-primary line">
                    <a routerLink="/forgotpassword" queryParamsHandling="preserve" class="ml-2">Forgot Password?</a>
                  </p>
                </div>
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-12">
                <!-- <button mat-raised-button type="submit" color="primary" (click)="setviewtype('pass')"
            class="float-right mt-2 btn-block">
            Next
          </button> -->
                <button mat-raised-button type="submit" color="primary" (click)="userInput()"
                  class="float-right mt-2 btn-block">
                  Next
                </button>

              </div>
            </div>
          </span>

          <span *ngIf="viewtype == 'cookie'">
            <div class="row" [ngClass]="{ 'fade-in-left-sm': bgTrans }">
              <div class="col-md-12">
                <h4 class="text-center text-primary mb-4">
                  Cookies are disabled
                </h4>
              </div>
              <div class="col-md-12">
                <h6 class="text-justify mb-4">
                  Your browser has cookies disabled. Make sure that your cookies are
                  enabled and try again..Learn more
                  <a href="https://support.google.com/accounts/answer/61416?hl=en-GB" target="_blank">Learn more</a>
                </h6>
                <button routerLink="/login" queryParamsHandling="preserve" mat-raised-button type="submit"
                  color="primary" (click)="setview('email')" class="float-right mt-2 btn-block">
                  Next
                </button>

                <!-- <button mat-raised-button type="submit" (click)="sendLoginCode()" color="primary"
                    class="float-right mt-2 btn-block">
                    Verify
                </button> -->
              </div>
            </div>
          </span>

          <span *ngIf="viewtype == 'pass' && cookieEnabled">
            <div class="row" [ngClass]="{ 'fade-in-left-sm': bgTrans }">
              <div class="col-md-12">
                <p>
                  <span class="text-muted"><i class="ti-arrow-left mr-2"
                      (click)="setviewtype('email')"></i>{{username}}</span>
                </p>
                <mat-form-field appearance="standard" class="w-100">
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" type="password" appAutoFocus
                    [type]="hide ? 'password' : 'text'" />
                  <mat-icon class="ui=" matSuffix (click)="hide = !hide" class="hide-icon"
                    [ngClass]="{'eye-color': hide}">
                    {{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                </mat-form-field>
                <label class="error">
                  {{ errorMessage }}
                </label>
                <label class="error" *ngIf="emailverified && tost">
                  Your email address is not verified. Click
                  <a routerLink="" queryParamsHandling="preserve" (click)="pagelink()">here</a>
                  to send the verification link.
                </label>
                <label class="error">{{ errMessage }}</label>
                <label class="success">{{ successMessage }}</label>
              </div>
              <div class="col-md-6">
                <div class="text-left">
                  <p color="primary" class="text-left text-primary line">
                    <a routerLink="/forgotpassword" queryParamsHandling="preserve">Forgot Password?</a>
                  </p>
                </div>
              </div>

              <div class="col-md-12">
                <button mat-raised-button type="submit" color="primary" (click)="TryLogin(loginForm.value)"
                  class="float-right mt-2 btn-block">
                  Sign in
                </button>
                <!-- <button *ngIf="isMB_Email==mobile" mat-raised-button type="submit" color="primary" (click)="verifyLoginCode()"
            class="float-right mt-2 btn-block">
            Sign in
          </button> -->

                <div>
                  <button mat-button class="mt-2" (click)="setviewtype('email')">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </span>
        </form>
        <!-- <span *ngIf="viewtype == 'email' || viewtype == 'pass'">
          <div class="row no-gutters pt-3 pb-3">
            <div class="col">
              <p class="d-flex justify-content-end">
                No account yet?
                <a routerLink="/register" queryParamsHandling="preserve" class="ml-2">Create account</a>
              </p>
            </div>
          </div>
          <div>
            <div class="txt1 text-center">
              <span>
                Or sign in using
              </span>
            </div>
            <div class="flex-social">
              <a class="pointer login100-social-item bg3" (click)="tryGoogleLogin()">
                <i class="fa fa-google text-white"></i>
              </a>
              <a class="pointer login100-social-item bg1" (click)="tryFacebookLogin()">
                <i class="fa fa-facebook text-white"></i>
              </a>

              <a class="pointer login100-social-item bg2" (click)="tryMicrosoftLogin()">
                <i class="fa fa-windows text-white"></i>
              </a>
              <a class="pointer login100-social-item bg4 directory" routerLink="/activedirectory"
                queryParamsHandling="preserve" (click)="loginusertype('adlogin')">
                <i class="fa fa-windows text-white" style="font-size: 15px;">
                  <span style="margin-top:4px; font-family: Arial, Helvetica, sans-serif;">Active Directory</span>
                </i>
              </a>
              <a class="pointer login100-social-item bg4 directory" routerLink="/phonelogin"
                queryParamsHandling="merge">
                <i class="fa fa-phone text-white" style="font-size: 15px;">
                  <span style="margin-top:4px; font-family: Arial, Helvetica, sans-serif;">Phone</span>
                </i>
              </a>

            </div>
          </div>
        </span> -->
      </div>
    </div>
    <div class="lic">
      <a href="https://triarqhealth.com/terms-of-use" target="_blank">Terms of Use</a>
    </div>
  </div>
</div>