<router-outlet></router-outlet>
<!-- <div class="MainBlock">
  <div class="content">
    <div class="info align-items-center">
      <router-outlet></router-outlet>
    </div>
    <div class="appblock">
      <div class="app app-block1">
        <div class="appLogo">
          <img src="assets/img/svg_icon/QCollege.svg" />
        </div>
      </div>
      <div class="app app-block2">
        <div class="appLogo">
          <img src="assets/img/svg_icon/EMR.svg" />
        </div>
      </div>
      <div class="app app-block3">
        <div class="appLogo">
          <img src="assets/img/svg_icon/QInsights.svg" />
        </div>
      </div>
      <div class="app app-block4">
        <div class="appLogo">
          <img src="assets/img/svg_icon/QAccount.svg" />
        </div>
      </div>
      <div class="app app-block5">
        <div class="appLogo">
          <img src="assets/img/svg_icon/Bot.svg" />
        </div>
      </div>
      <div class="app app-block6">
        <div class="appLogo">
          <img src="assets/img/svg_icon/QAnalytics.svg" />
        </div>
      </div>
      <div class="app app-block7">
        <div class="appLogo">
          <img src="assets/img/svg_icon/QManagement.svg" />
        </div>
      </div>
      <div class="app app-block8">
        <div class="appLogo">
          <img src="assets/img/svg_icon/PM.svg" />
        </div>
      </div>
      <div class="app app-block9">
        <div class="appLogo">
          <img src="assets/img/svg_icon/HUB0003.svg" />
        </div>
      </div>
      <div class="app app-block10">
        <div class="appLogo">
          <img src="assets/img/svg_icon/QPathway.svg" />
        </div>
      </div>
      <div class="app app-block11">
        <div class="appLogo">
          <img src="assets/img/svg_icon/Qonductor.svg" />
        </div>
      </div>
      <div class="app app-block12">
        <div class="appLogo">
          <img src="assets/img/svg_icon/QAdmin.svg" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="rotation-block bg-white w-100">
  <img src="assets/img/rotate-img.png" alt="" class="w-100">
</div> -->