import { Component, OnInit } from '@angular/core';
import { AuthService } from "../core/auth.service";
import { Router, Params } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as firebase from "firebase/app";
import { UserService } from '../core/user.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  registerForm: FormGroup;
  showRecoverbtn = true;
  errorMessage: string = "";
  successMessage: string = "";


  public viewtype = "email";
  public bgTrans: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.registerForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });
  }


  // forgotpassword(value){

  //   this.authService.sendPasswordResetEmail(value)
  //       this.errorMessage = "";
  //       this.successMessage = "Password reset link has been sent to your account";

  // }


  forgotpassword(value) {

    this.userService.isEmailActiveForPasswordRecovery(value.email).subscribe((res) => {
      if(res)
      {
        if(res.isactive == true){
          this.authService.sendPasswordResetEmail(value).then(
              res => {
                // console.log("Password Reset Success")
                //  console.log(res);
                this.errorMessage = "";
                this.successMessage = "Password reset link has been sent to your account. Click on the link to reset the password.";
                this.showRecoverbtn = false;
              },
              err => {
                // console.log("Password Reset Error")
                //  console.log(err);
                this.errorMessage = err.message;
                this.successMessage = "";
              }
            );
        }
        else{
          if(res.message){
            this.errorMessage = "User not found.";
            this.successMessage = "";
          }
          else{
            this.errorMessage = "Your account is currently inactive.";
            this.successMessage = "";
          }
        }
      }
    })
  }

  tryRegister(value) {

    this.authService.doRegister(value).then(
      res => {
        //  console.log("doRegister", res);
        this.errorMessage = "";
        this.successMessage = "Your account has been created";
      },
      err => {
        //  console.log(err);
        this.errorMessage = err.message;
        this.successMessage = "";
      }
    );

  }
  setviewtype(type) {
    this.viewtype = type;
    this.bgTrans = true;
  }
}
