<!-- <nav class="navbar navbar-default navbar-static-top navbar-inverse">
  <div class="container"></div>
</nav>

<nav class="navbar navbar-top navbar-expand-lg navbar-dark bg-secondary">
  <div class="container">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarsExample07"
      aria-controls="navbarsExample07"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarsExample07">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            (click)="logout()"
            class="navbar-text navbar-right navbar-link text-white"
            >Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav> -->

<div>
  <div class="row">
    <div class="col ml-4">
      <h5 class="text-white font-weight-normal">Please wait...</h5>
    </div>
  </div>

  <div class="row" *ngIf="this.useSetCookie === true">
    <iframe [src]="this.setSIDURL_testapp | safe" style="display: none"></iframe>
  </div>

  <!-- <div class="row">
    <iframe [src]="this.setSIDURL_myqone | safe" style="display: none"></iframe>
  </div> -->
  <!--  <div class="row">
    <iframe [src]="this.setSIDURL_obuzz | safe" style="display: none"></iframe>
  </div> -->
  <!-- <div class="row">
    <iframe [src]="this.setSIDURL_qinsights | safe" style="display: none"></iframe>
  </div>
  <div class="row">
    <iframe [src]="this.setSIDURL_qadmin | safe" style="display: none"></iframe>
  </div>
  <div class="row">
    <iframe [src]="this.setSIDURL_qonductor | safe" style="display: none"></iframe>
  </div>
  <div class="row">
    <iframe [src]="this.setSIDURL_qcollege | safe" style="display: none"></iframe>
  </div> -->
  <!--   <div class="row">
    <iframe [src]="'https://thesmartclinic.triarqclouds.com/ck.html?UID=' + this.uid  | safe"></iframe>
    <iframe [src]="'http://172.21.120.46:5000/assets/setSID.html?UID='  + this.uid + '&' + Token=' + this.token | safe"></iframe>
    <iframe [src]="this.setSIDURL_testapp | safe" style="display: none"></iframe>
  </div> -->
</div>