export const environment = {
  production: true,
  currentEnvironment: "Development",
  firebase: {
    apiKey: "AIzaSyAf6l7qoiXHWg1n4V0gqaWC-jXm4yvVkwA",
    authDomain: "qpathways-dev.firebaseapp.com",
    databaseURL: "https://qpathways-dev.firebaseio.com",
    projectId: "qpathways-dev",
    storageBucket: "qpathways-dev.appspot.com",
    messagingSenderId: "413178660353"
  },
  service_url: "https://qore-dev.myqone.com/auth/",
  // service_url: "https://microservices.qpathways.com/auth/", 1

  myqone_url: "https://dev.myqone.com",
  qinsights_url: "https://qinsight-dev.myqone.com",
  qadmin_url: "https://qadmin-dev.myqone.com",
  qonductor_url: "https://qonductor-dev.myqone.com",
  obuzz_url: "https://obuzz-dev.myqone.com",
  qcollege_url: "https://qcollege-dev.myqone.com",
  qbots_url: "https://qbotcontroller-dev.myqone.com/Controls",
  banq_url: "https://banq-dev.myqone.com",
  qpathways_url: "https://dev.qpathways.com",
  qpathways_admin_url: "https://admin-dev.qpathways.com",
  bcbsm_url: "https://blueprint-dev.myqone.com",
  adToken: "TXpsak1UVmxPRFl0TURrd015MDBOR0V6TFdFMU9ERXRNV00wWWpsak1XTTNPRFkw",
  host: "https://devservices.qpathways.com/otc/",
  npireg: "https://devservices.qpathways.com/npi/",
  auditUrl: "https://qore-dev.myqone.com/",
  qwebemr_url: "https://qemr-dev.myqone.com",
  pat_engagement_url: "https://dev.obuzz.net",
  pat_engagement_web: "https://dev.obuzz.net/puthash/setSID",
 covid_assessment_task: 'feddcd49-9025-11ea-b856-bbe8b055f377',
 algokey: "2CeqdJ9d2BCyEj0Pwtb1",
 dToken:"U2FsdGVkX19M0bLtXlxxF8UcwS7wQ3Oq8ZtbyI2cVXBSB48HOEovJZlKrP2RRMUCMiCZ4vVGSvPWM8POtilZrtR05N7veCCwdlwjLN7ZI5P+d5kacQa5uF6kqdFKUdAQlXSh2uSxjCanmdCczIsUHmleJtWwsedk7i1Q059CKeAwTNnQ/Bl9z+Nhdd/rZuXMYcrZJP19Y7zzrer7iHNKTpp7z47kDGpOPWwelIBd4vjjY2ECm6h0QQBqNtxY2xitbA9zyrdi8LraprWw3ESB0av4XFa6Tl7XXQVOCtvM56Vs0gCoSAK+ATBcT1c7yrlp12SVym6j+NsmN7wg5DHG8K7hLWc3M9A1r7Fc7gD/vTF+/u8XM/9N0NE/Q6q1RtscCwuKRBm4DdXesuQ3f3w9llIJyTUyHLZgg79Pl8wVSs0e5sfQ1UFTg4WEFFmk+MnNTB/VVSWb3nqWjxHuAH/NmBqUXiySu8OeNpD9MBvblW5yj1pFNb2xEKGQWS8UwzGYvylgcZvjM8h4VqhwH+H+zukDb95cX7RYhawOgSLK3Uv7mJJjna0Ak3oIll9CqdCfI5XymalvP6x6g9F1DtwUr/jVor1XZmEu9l7b+1DtcK+w4UpZwk/WpR9KluTT3sQXhSDT7ODUzTufOuObArrLsl24a5uRFB/dTCpZ3PBH4hUbPAUOPliaCn0QUE1oI0I96DeH1Q9r0/OQZ4gRuryWotx2XSY0B43SO9r3eOUecV1eBZQ5BQMy50A0n4zrtZaYvR9PaGY2lLwLtu+z/CsWpbe/hBTgYPrLjGb8nmNTZ0h0WYtv1jsQRY+ePkaTctN7h/WQV1fkjT2iyIdnPORVknhu1Ud0LIDErQIkuu26X0Mxvg46KTbvCSoRafyuRs44swY2Rr3JipkwRhz5Lf7ztw2nzPbTcOmUaduFxgV1ibWaKzcn5OZbj0xzOcoY/Ln2pqj+BVFpSJnnzbcZupqSBx2zSi+piFAcY1dck+A5H2YctowBJNUnicA5+V8cFB4mTyWJnROEZpuPBqoW/9WhG/ZgoZSWBJGP40QNXZEKHnncKSvVKYm/lI9eqvdhZ70BpXgakXO3bBTrOsfRsUqybsJQN7D57jiwff9C07cjK6s=",
 DuoService: 'https://qonductor-dev.myqone.com/qduoconnect/'
};
