import { Component, OnInit } from '@angular/core';
// import * as firebase from "firebase/app";
import { environment } from 'src/environments/environment';
import { ProfileService } from '../Services/profile/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from "@angular/fire/auth";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../core/auth.service';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { customValidator } from '../shared/customValidator';
import { UserService } from '../core/user.service';
import { stringify } from '@angular/compiler/src/util';
import { AuditService } from '../AuditService/audit.service';
import  firebase from "firebase/app";
@Component({
  selector: 'app-emailverification',
  templateUrl: './emailverification.component.html',
  styleUrls: ['./emailverification.component.css']
})
export class EmailverificationComponent implements OnInit {
  resetForm: FormGroup;
  resetFlag = false;
  fireuser: any;
  mode: any;
  userid: string;
  token: string;
  algokey: string = "glotest123456789";
  oobcode: any; hide = true; hide1 = true;
  lang: any;
  apiKey: any;
  username: string = "";
  errorMessage: string = ""; successMessage: string = "";
  userType: string;
  constructor(private profileService: ProfileService,
    private cookieService: CookieService,
    private location: Location,
    public userService: UserService,
    private router: Router,
    public authService: AuthService,
    private activatedroute: ActivatedRoute,
    private fireauth: AngularFireAuth,
    private fb: FormBuilder,
    private auditService: AuditService) {

  }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((qParam) => {
      if (qParam && qParam.utyp) {
        this.userType = qParam.utyp;
      }
    })
    this.username = this.userService.getSessionStorage("username");
    this.activatedroute.queryParams.subscribe((param) => {
      this.mode = param['mode'];
      this.oobcode = param['oobCode'];
      this.apiKey = param['apiKey'];
      this.lang = param['lang'];
      // this.userType = param['utyp'];
      this.resetForm = this.fb.group({
        password: ["",
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            customValidator.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            customValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            customValidator.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            customValidator.patternValidator(
              /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8),
            Validators.maxLength(20),

            customValidator.cannotContainspace1
          ])

        ],// [Validators.required, customValidator.strongPasswordValidation]],
        cpassword: ["", Validators.required]


      }, {
        validators: customValidator.passwordsShouldMatch
      });
      // console.log(this.oobcode);
      switch (this.mode) {

        case 'verifyEmail':
          // Display email verification handler and UI.
          this.handleVerifyEmail();
          break;
        default:
        // Error: invalid mode.
      }
    },
      (ex) => {
        console.log("error", ex);
      })
  }

  resetpassword(value) {
    //console.log("password", value.password);
    if (this.mode === "resetPassword") {
      this.handleResetPassword(this.oobcode);
    }
  }

  // Working  verify email method
  handleVerifyEmail() {
    this.profileService.emailhandler(this.oobcode).subscribe(res => {
      this.fireuser = res;
      //  console.log("userDetails", res);
      this.errorMessage = "";
      this.successMessage = "Your email has been verified. You can now sign in with your new account.";

      this.profileService.markEmailVerified(this.fireuser.localId).subscribe((res: any) => {
      });
    },
      err => {

        console.log("Error : ", err);
        this.successMessage = "";
        this.errorMessage = "The verification link is already used or expired.";//err.message;

      });

  }
  continue() {
    //  console.log("after click continue");
    this.logout();
  }
  // end
  //this.handleResetPassword(actionCode);
  handleResetPassword(oobcode) {
    let token;
    var accountEmail;
    var newPassword = this.resetForm.value.password;
      // console.log("newpassword", newPassword);
    this.fireauth.verifyPasswordResetCode(oobcode).then(res => {
      accountEmail = res;   //  Stored in database

      let user: { email: string, password: string } = { email: accountEmail, password: newPassword };


      //   console.log("verifyPasswordResetCode response", accountEmail);
      this.fireauth.confirmPasswordReset(oobcode, newPassword).then(resp => {
        this.authService.doLogin(user).then(
          res => {
            // token = res.user.refreshToken;

            //console.log("Got Do Login Response ", res, res.user.uid, res.user['uid']);
            firebase.auth().currentUser.getIdToken(true)
            .then((token) => {
              this.auditService.getid(res.user.uid); this.auditService.getname(res.user.displayName);
              this.resetPassword(newPassword, token, accountEmail);
            });
          },
          err => {
            console.log("error", err);

          }
        );
        this.resetFlag = true;
        this.errorMessage = "";
        this.successMessage = "Your password has been reset successfully. Click on Continue to login.";
        //   console.log("confirmPasswordReset response", resp);
        //   this.router.navigate(['/login']);
      }, err => {
        this.successMessage = "";
        this.errorMessage = err.message;

        console.log("error", err);
      })

    }, err => {
      this.errorMessage = err.message;

      console.log("error", err);
    })

  }
  resetPassword(newPassword, token, accountEmail) {

    let newpwd = this.encryptPassword(newPassword);
    let user: { username: string, newpassword: string, sendemail: boolean } = {
      username: accountEmail, newpassword: (newpwd).toString(), sendemail: false
    };
    this.profileService.resetpasswordbyemail(user, token).subscribe((res: any) => {
      //console.log("reset response", res);
      this.userService.setSessionStorage("username", accountEmail);
      this.auditService.callApI("Password Recovery Performed","");

    }, err => {
      console.log("error in reset ", err);
    })
  }
  encryptPassword(password: string) {
    var base64Key = CryptoJS.enc.Utf8.parse(this.algokey);
    var iv = CryptoJS.enc.Utf8.parse(this.algokey);
    var newencryptedpass = CryptoJS.AES.encrypt(
      password,
      base64Key,
      {
        keySize: 16,
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return newencryptedpass;
  }
  logout() {
    this.authService.doLogout().then(
      res => {
        this.cookieService.delete('UID');
        this.cookieService.delete('AID');
        //Aniket
        //  this.location.back();
        if (this.userType === 'pat') {
          this.router.navigate(['login'],
          // { queryParams: { continue: environment.pat_engagement_web, src: 'email', utyp: 'pat' } }
          );
        }
        else {
          this.router.navigate(['login']);
        }
      },
      error => {
        if (this.userType === 'pat') {
          this.router.navigate(['login'],
          //  { queryParams: { continue: environment.pat_engagement_web, src: 'email', utyp: 'pat' } }
           );
        }
        else {
          this.router.navigate(['login']);
        }
        console.log("Logout error", error);
      }
    );
  }

  back() {
    this.router.navigate(['/login']);
  }

}
